<template>
  <div class="top-navigation-bar">
    <router-link to="/homeFeed" :class="[

          currentLink === '/homeFeed' ? 'parent_a' : 'parent_b'

        ]">
      <svg
        @click="goToSurvey('/homeFeed')"
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 23 20"
        fill="none"
        :class="[
          currentLink === '/homeFeed' ? 'active' : 'inactive',
          'home-lg-alt-1 main_link',
        ]"
      >
        <g clip-path="url(#clip0_724_1652)">
          <path
            d="M11.5004 4.49258L2.77437 12.0203C2.70969 12.0773 2.62703 12.1039 2.55595 12.1512V19.3754C2.55595 19.5411 2.62326 19.7001 2.74308 19.8173C2.86289 19.9345 3.0254 20.0004 3.19484 20.0004H8.30595C8.4754 20.0004 8.6379 19.9345 8.75772 19.8173C8.87753 19.7001 8.94484 19.5411 8.94484 19.3754V14.3754C8.94484 14.2096 9.01215 14.0507 9.13197 13.9334C9.25178 13.8162 9.41429 13.7504 9.58373 13.7504H13.4171C13.5865 13.7504 13.749 13.8162 13.8688 13.9334C13.9886 14.0507 14.056 14.2096 14.056 14.3754V19.3754C14.056 19.5411 14.1233 19.7001 14.2431 19.8173C14.3629 19.9345 14.5254 20.0004 14.6948 20.0004H19.806C19.9754 20.0004 20.1379 19.9345 20.2577 19.8173C20.3775 19.7001 20.4448 19.5411 20.4448 19.3754V12.1527C20.377 12.1074 20.2963 12.0816 20.2348 12.0277L11.5004 4.49258ZM22.7884 9.23007L12.5685 0.401951C12.276 0.143241 11.8957 0 11.5014 0C11.1071 0 10.7268 0.143241 10.4343 0.401951L0.212029 9.23007C0.149586 9.28493 0.0988021 9.35128 0.0625783 9.42534C0.0263544 9.49939 0.00540059 9.57971 0.000913761 9.66169C-0.00357307 9.74367 0.00849503 9.82571 0.0364286 9.90313C0.0643623 9.98055 0.107614 10.0518 0.163713 10.1129L1.01863 11.0434C1.0747 11.1044 1.14252 11.1541 1.21823 11.1896C1.29393 11.225 1.37603 11.2455 1.45983 11.2499C1.54364 11.2543 1.6275 11.2425 1.70664 11.2151C1.78578 11.1878 1.85864 11.1455 1.92106 11.0906L11.0779 3.18906C11.1947 3.08833 11.3449 3.03274 11.5006 3.03274C11.6562 3.03274 11.8065 3.08833 11.9233 3.18906L21.0801 11.0902C21.1426 11.1451 21.2154 11.1874 21.2946 11.2147C21.3737 11.2421 21.4576 11.2539 21.5414 11.2495C21.6252 11.2451 21.7073 11.2246 21.783 11.1892C21.8587 11.1537 21.9265 11.1041 21.9826 11.043L22.8371 10.1125C22.9502 9.98915 23.0086 9.82689 22.9995 9.66142C22.9904 9.49594 22.9144 9.34079 22.7884 9.23007Z"
            fill="currentcolor"
          />
        </g>
        <defs>
          <clipPath id="clip0_724_1652">
            <rect width="23" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </router-link>
    <router-link to="/surveyDefault" :class="[
          currentLink === '/surveyDefault' ? 'parent_a' : 'parent_b'

        ]">
      <svg
        @click="goToSurvey('/surveyDefault')"
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="20"
        viewBox="0 0 15 20"
        fill="none"
        :class="[
          currentLink === '/surveyDefault' ? 'active' : 'inactive',
          'home-lg-alt-1 main_link',
        ]"
      >
        <g clip-path="url(#clip0_724_1654)">
          <path
            d="M13.125 2.5H10C10 1.12109 8.87891 0 7.5 0C6.12109 0 5 1.12109 5 2.5H1.875C0.839844 2.5 0 3.33984 0 4.375V18.125C0 19.1602 0.839844 20 1.875 20H13.125C14.1602 20 15 19.1602 15 18.125V4.375C15 3.33984 14.1602 2.5 13.125 2.5ZM7.5 1.5625C8.01953 1.5625 8.4375 1.98047 8.4375 2.5C8.4375 3.01953 8.01953 3.4375 7.5 3.4375C6.98047 3.4375 6.5625 3.01953 6.5625 2.5C6.5625 1.98047 6.98047 1.5625 7.5 1.5625ZM2.5625 8.17969L3.05859 7.6875C3.14062 7.60547 3.27344 7.60547 3.35547 7.6875L4.16016 8.5L6.01953 6.65625C6.10156 6.57422 6.23438 6.57422 6.31641 6.65625L6.80859 7.15234C6.89062 7.23438 6.89062 7.36719 6.80859 7.44922L4.30078 9.93359C4.21875 10.0156 4.08594 10.0156 4.00391 9.93359L2.5625 8.47656C2.48047 8.39453 2.48047 8.26172 2.5625 8.17969ZM3.75 15.3125C3.23047 15.3125 2.8125 14.8945 2.8125 14.375C2.8125 13.8555 3.23047 13.4375 3.75 13.4375C4.26953 13.4375 4.6875 13.8555 4.6875 14.375C4.6875 14.8945 4.26953 15.3125 3.75 15.3125ZM12.5 14.6875C12.5 14.8594 12.3594 15 12.1875 15H6.5625C6.39062 15 6.25 14.8594 6.25 14.6875V14.0625C6.25 13.8906 6.39062 13.75 6.5625 13.75H12.1875C12.3594 13.75 12.5 13.8906 12.5 14.0625V14.6875ZM12.5 9.6875C12.5 9.85938 12.332 10 12.125 10H6.01953L7.28125 8.75H12.125C12.332 8.75 12.5 8.89062 12.5 9.0625V9.6875Z"
            fill="currentcolor"
          />
        </g>
        <defs>
          <clipPath id="clip0_724_1654">
            <rect width="15" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </router-link>
    <router-link to="/researchDefault" :class="[

          currentLink === '/researchDefault' ? 'parent_a' : 'parent_b'

        ]">
      <svg
        @click="goToSurvey('/researchDefault')"
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        :class="[
          currentLink === '/researchDefault' ? 'active' : 'inactive',
          'home-lg-alt-1 main_link',
        ]"
      >
        <g clip-path="url(#clip0_724_1656)">
          <path
            d="M19.8633 18.8125L14.8242 13.7734C14.7344 13.6836 14.6172 13.6367 14.4922 13.6367H14.0898C15.4297 12.1875 16.25 10.2539 16.25 8.125C16.25 3.63672 12.6133 0 8.125 0C3.63672 0 0 3.63672 0 8.125C0 12.6133 3.63672 16.25 8.125 16.25C10.2539 16.25 12.1875 15.4297 13.6367 14.0938V14.4922C13.6367 14.6172 13.6875 14.7344 13.7734 14.8242L18.8125 19.8633C18.9961 20.0469 19.293 20.0469 19.4766 19.8633L19.8633 19.4766C20.0469 19.293 20.0469 18.9961 19.8633 18.8125ZM8.125 15C4.32422 15 1.25 11.9258 1.25 8.125C1.25 4.32422 4.32422 1.25 8.125 1.25C11.9258 1.25 15 4.32422 15 8.125C15 11.9258 11.9258 15 8.125 15Z"
            fill="currentcolor"
          />
        </g>
        <defs>
          <clipPath id="clip0_724_1656">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </router-link>
    <router-link to="/messages"   :class="[
          currentLink === '/messages' ? 'parent_a' : ''
        ]">
       
      <svg
        @click="goToSurvey('/messages')"
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        :class="[
          currentLink === '/messages' ? 'active' : 'inactive',
          'home-lg-alt-1 main_link',
        ]"
      >
        <path
          d="M19.6211 7.45312C19.7734 7.33203 20 7.44531 20 7.63672V15.625C20 16.6602 19.1602 17.5 18.125 17.5H1.875C0.839844 17.5 0 16.6602 0 15.625V7.64062C0 7.44531 0.222656 7.33594 0.378906 7.45703C1.25391 8.13672 2.41406 9 6.39844 11.8945C7.22266 12.4961 8.61328 13.7617 10 13.7539C11.3945 13.7656 12.8125 12.4727 13.6055 11.8945C17.5898 9 18.7461 8.13281 19.6211 7.45312ZM10 12.5C10.9062 12.5156 12.2109 11.3594 12.8672 10.8828C18.0508 7.12109 18.4453 6.79297 19.6406 5.85547C19.8672 5.67969 20 5.40625 20 5.11719V4.375C20 3.33984 19.1602 2.5 18.125 2.5H1.875C0.839844 2.5 0 3.33984 0 4.375V5.11719C0 5.40625 0.132812 5.67578 0.359375 5.85547C1.55469 6.78906 1.94922 7.12109 7.13281 10.8828C7.78906 11.3594 9.09375 12.5156 10 12.5Z"
          fill="currentcolor"
        />
      </svg>
    </router-link>
  </div>
</template>

<script>
import { routerLink } from "vue-router";

export default {
  components: {
    "router-link": routerLink,
  },
  data() {
    return {
      currentLink: null,
    };
  },
   created() {
    this.currentLink = this.$router.currentRoute.path;
    console.log("Initial currentLink:", this.currentLink);
  },
  methods: {
    goToSurvey(link) {
      console.log("going to survey");
      this.$router.push(link);
      this.currentLink = this.$router.currentRoute.path;


      console.log(this.$router.currentRoute.path);
    },
  },
  name: "TopNavigationBar",
  props: ["place"],
};
</script>

<style scoped>
.top-navigation-bar {
  align-items: center;
  background-color: var(--white);
  border-radius: 100px;
  box-shadow: 0px 0px 12px #0000000d;
  display: flex;
  height: 60px;
  margin-left: 21px;
  min-width: 334px;
  padding: 5px 20px;
  justify-content: space-between;
}

.flex-col-4 {
  align-items: center;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  gap: 2px;
  min-height: 58px;
  width: 44px;
}

.overlap-group-4 {
  align-items: center;
  background-color: var(--x259b35);
  border-radius: 100px;
  display: flex;
  height: 44px;
  justify-content: flex-end;
  min-width: 44px;
  padding: 0 10px;
}

.main_link {
  height: 20px;
  width: 23px;
   cursor: pointer;
  color: #a5ac9d;
}

.place {
  color: var(--style);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-xs);
  font-weight: 500;
  height: 12px;
  letter-spacing: 0;
  line-height: normal;
  margin-left: 1px;
  min-width: 29px;
  white-space: nowrap;
}

.parent_a {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: #259b3521;
  display: flex;
  align-items: center;
 
  justify-content: center;

}
.parent_b {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
 
  justify-content: center;

}

.active {
  color: #259b35 !important;
}
</style>
