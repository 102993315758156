<template>
  <div class="container-center-horizontal">
    <form
      class="home-feed-student-default screen"
      name="form3"
      action="form3"
      method="post"
    >
      <left-navigation-bar v-bind="leftNavigationBarProps" />
      <div class="flex-col">
        <top-navigation-bar />
        <div class="broadcast-feed">
          <div class="overlap-HomeFeedStudentDefaultgroup1">
            <div
              class="frame-2342"
              style="display: flex; align-items: start; flex-direction: column"
            >
              <!--Chat-->
              <div class="chat_main">
                <div class="chat">
                  <div class="chat_users">
                    <div class="chat_user_heading">
                      <h1>Messages</h1>
                      <div class="message_headings_icons">
                        <div class="message_headings_icons_search_bar">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                          >
                            <path
                              d="M17.877 16.9312L13.3418 12.3961C13.2609 12.3152 13.1555 12.273 13.043 12.273H12.6809C13.8867 10.9688 14.625 9.22852 14.625 7.3125C14.625 3.27305 11.352 0 7.3125 0C3.27305 0 0 3.27305 0 7.3125C0 11.352 3.27305 14.625 7.3125 14.625C9.22852 14.625 10.9688 13.8867 12.273 12.6844V13.043C12.273 13.1555 12.3188 13.2609 12.3961 13.3418L16.9312 17.877C17.0965 18.0422 17.3637 18.0422 17.5289 17.877L17.877 17.5289C18.0422 17.3637 18.0422 17.0965 17.877 16.9312ZM7.3125 13.5C3.8918 13.5 1.125 10.7332 1.125 7.3125C1.125 3.8918 3.8918 1.125 7.3125 1.125C10.7332 1.125 13.5 3.8918 13.5 7.3125C13.5 10.7332 10.7332 13.5 7.3125 13.5Z"
                              fill="currentcolor"
                            />
                          </svg>
                        </div>
                        <div class="message_headings_icons_search_bar">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="33"
                            height="33"
                            viewBox="0 0 33 33"
                            fill="none"
                          >
                            <g clip-path="url(#clip0_190_3271)">
                              <path
                                d="M16.5 0C13.2366 0 10.0465 0.967708 7.3331 2.78075C4.61969 4.59379 2.50484 7.17074 1.256 10.1857C0.00714922 13.2007 -0.319606 16.5183 0.31705 19.719C0.953706 22.9197 2.52518 25.8597 4.83275 28.1673C7.14031 30.4748 10.0803 32.0463 13.281 32.683C16.4817 33.3196 19.7993 32.9929 22.8143 31.744C25.8293 30.4952 28.4062 28.3803 30.2193 25.6669C32.0323 22.9535 33 19.7634 33 16.5C32.9953 12.1254 31.2554 7.93129 28.162 4.83796C25.0687 1.74464 20.8746 0.00473149 16.5 0ZM22 17.875H17.875V22C17.875 22.3647 17.7301 22.7144 17.4723 22.9723C17.2144 23.2301 16.8647 23.375 16.5 23.375C16.1353 23.375 15.7856 23.2301 15.5277 22.9723C15.2699 22.7144 15.125 22.3647 15.125 22V17.875H11C10.6353 17.875 10.2856 17.7301 10.0277 17.4723C9.76987 17.2144 9.62501 16.8647 9.62501 16.5C9.62501 16.1353 9.76987 15.7856 10.0277 15.5277C10.2856 15.2699 10.6353 15.125 11 15.125H15.125V11C15.125 10.6353 15.2699 10.2856 15.5277 10.0277C15.7856 9.76987 16.1353 9.625 16.5 9.625C16.8647 9.625 17.2144 9.76987 17.4723 10.0277C17.7301 10.2856 17.875 10.6353 17.875 11V15.125H22C22.3647 15.125 22.7144 15.2699 22.9723 15.5277C23.2301 15.7856 23.375 16.1353 23.375 16.5C23.375 16.8647 23.2301 17.2144 22.9723 17.4723C22.7144 17.7301 22.3647 17.875 22 17.875Z"
                                fill="#259B35"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_190_3271">
                                <rect width="33" height="33" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div class="scrollable">
                      <div class="user_heading">
                        <img
                          src="https://images.unsplash.com/photo-1688314846356-ccc51cd0bcfd?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2450&q=80"
                          alt=""
                        />
                        <div class="">
                          <h1>Duke University</h1>
                          <p>Available Scholarships...</p>
                        </div>
                      </div>
                      <div class="user_heading">
                        <img
                          src="https://images.unsplash.com/photo-1688314846356-ccc51cd0bcfd?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2450&q=80"
                          alt=""
                        />
                        <div class="">
                          <h1>Duke University</h1>
                          <p>Available Scholarships...</p>
                        </div>
                      </div>
                      <div class="user_heading">
                        <img
                          src="https://images.unsplash.com/photo-1688314846356-ccc51cd0bcfd?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2450&q=80"
                          alt=""
                        />
                        <div class="">
                          <h1>Duke University</h1>
                          <p>Available Scholarships...</p>
                        </div>
                      </div>
                      <div class="user_heading">
                        <img
                          src="https://images.unsplash.com/photo-1688314846356-ccc51cd0bcfd?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2450&q=80"
                          alt=""
                        />
                        <div class="">
                          <h1>Duke University</h1>
                          <p>Available Scholarships...</p>
                        </div>
                      </div>
                      <div class="user_heading">
                        <img
                          src="https://images.unsplash.com/photo-1688314846356-ccc51cd0bcfd?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2450&q=80"
                          alt=""
                        />
                        <div class="">
                          <h1>Duke University</h1>
                          <p>Available Scholarships...</p>
                        </div>
                      </div>
                      <div class="user_heading">
                        <img
                          src="https://images.unsplash.com/photo-1688314846356-ccc51cd0bcfd?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2450&q=80"
                          alt=""
                        />
                        <div class="">
                          <h1>Duke University</h1>
                          <p>Available Scholarships...</p>
                        </div>
                      </div>
                      <div class="user_heading">
                        <img
                          src="https://images.unsplash.com/photo-1688314846356-ccc51cd0bcfd?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2450&q=80"
                          alt=""
                        />
                        <div class="">
                          <h1>Duke University</h1>
                          <p>Available Scholarships...</p>
                        </div>
                      </div>
                      <div class="user_heading">
                        <img
                          src="https://images.unsplash.com/photo-1688314846356-ccc51cd0bcfd?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2450&q=80"
                          alt=""
                        />
                        <div class="">
                          <h1>Duke University</h1>
                          <p>Available Scholarships...</p>
                        </div>
                      </div>
                      <div class="user_heading">
                        <img
                          src="https://images.unsplash.com/photo-1688314846356-ccc51cd0bcfd?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2450&q=80"
                          alt=""
                        />
                        <div class="">
                          <h1>Duke University</h1>
                          <p>Available Scholarships...</p>
                        </div>
                      </div>
                      <div class="user_heading">
                        <img
                          src="https://images.unsplash.com/photo-1688314846356-ccc51cd0bcfd?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2450&q=80"
                          alt=""
                        />
                        <div class="">
                          <h1>Duke University</h1>
                          <p>Available Scholarships...</p>
                        </div>
                      </div>
                      <div class="user_heading">
                        <img
                          src="https://images.unsplash.com/photo-1688314846356-ccc51cd0bcfd?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2450&q=80"
                          alt=""
                        />
                        <div class="">
                          <h1>Duke University</h1>
                          <p>Available Scholarships...</p>
                        </div>
                      </div>
                      <div class="user_heading">
                        <img
                          src="https://images.unsplash.com/photo-1688314846356-ccc51cd0bcfd?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2450&q=80"
                          alt=""
                        />
                        <div class="">
                          <h1>Duke University</h1>
                          <p>Available Scholarships...</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="chat_body">
                    <div class="chat_body_user_heading">
                      <img
                        src="https://images.unsplash.com/photo-1688314846356-ccc51cd0bcfd?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2450&q=80"
                        alt=""
                      />
                      <div class="">
                        <h1>Duke University</h1>
                        <p>Available Scholarships...</p>
                        <p>View Profile</p>
                      </div>
                    </div>

                    <div class="chat_body_main">
                      <div class="single_message">
                        <img
                          src="https://images.unsplash.com/photo-1688314846356-ccc51cd0bcfd?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2450&q=80"
                        />
                        <div class="message_div">
                          <p>I’d like to discuss: Available Scholarships</p>
                        </div>
                      </div>

                      <div class="single_message_right">
                        <div class="message_div"><p>Yes Available</p></div>
                        <img
                          src="https://images.unsplash.com/photo-1688314846356-ccc51cd0bcfd?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2450&q=80"
                        />
                      </div>
                      <div class="single_message_right">
                        <div class="message_div"><p>Yes Available</p></div>
                        <img
                          src="https://images.unsplash.com/photo-1688314846356-ccc51cd0bcfd?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2450&q=80"
                        />
                      </div>
                      <div class="single_message_right">
                        <div class="message_div"><p>Yes Available</p></div>
                        <img
                          src="https://images.unsplash.com/photo-1688314846356-ccc51cd0bcfd?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2450&q=80"
                        />
                      </div>
                      <div class="single_message">
                        <img
                          src="https://images.unsplash.com/photo-1688314846356-ccc51cd0bcfd?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2450&q=80"
                        />
                        <div class="message_div">
                          <p>I’d like to discuss: Available Scholarships</p>
                        </div>
                      </div>
                      <div class="single_message">
                        <img
                          src="https://images.unsplash.com/photo-1688314846356-ccc51cd0bcfd?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2450&q=80"
                        />
                        <div class="message_div">
                          <p>I’d like to discuss: Available Scholarships</p>
                        </div>
                      </div>
                      <div class="single_message">
                        <img
                          src="https://images.unsplash.com/photo-1688314846356-ccc51cd0bcfd?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2450&q=80"
                        />
                        <div class="message_div">
                          <p>I’d like to discuss: Available Scholarships</p>
                        </div>
                      </div>
                      <div class="single_message">
                        <img
                          src="https://images.unsplash.com/photo-1688314846356-ccc51cd0bcfd?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2450&q=80"
                        />
                        <div class="message_div">
                          <p>I’d like to discuss: Available Scholarships</p>
                        </div>
                      </div>
                      <div class="single_message">
                        <img
                          src="https://images.unsplash.com/photo-1688314846356-ccc51cd0bcfd?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2450&q=80"
                        />
                        <div class="message_div">
                          <p>I’d like to discuss: Available Scholarships</p>
                        </div>
                      </div>
                    </div>

                    <div class="chat_form">
                      <input placeholder="Your message here" />
                      <div class="send_message">
                        <button>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="23"
                            height="23"
                            viewBox="0 0 23 23"
                            fill="none"
                          >
                            <path
                              d="M7.65332 21.2197C7.65332 21.3575 7.72985 21.4838 7.85154 21.5482C7.97313 21.6126 8.12098 21.6043 8.23464 21.5265L10.7944 19.7787L7.65337 18.2816V21.2197H7.65332Z"
                              fill="#259B35"
                            />
                            <path
                              d="M22.8805 1.50743C22.8102 1.44261 22.7194 1.40869 22.6277 1.40869C22.5724 1.40869 22.5172 1.42096 22.4651 1.44661L0.367267 12.2862C0.141403 12.3972 -0.00152111 12.6278 1.22156e-05 12.8801C0.00154554 13.1321 0.146886 13.3612 0.374376 13.4697L6.19831 16.2448L17.8432 6.67357L7.64126 16.9323L14.8964 20.3892C14.9856 20.4319 15.0829 20.4531 15.1791 20.4531C15.2613 20.4531 15.3426 20.4382 15.4201 20.4077C15.5891 20.3409 15.7234 20.2071 15.7904 20.0381L22.9736 1.91697C23.0305 1.77437 22.9934 1.61132 22.8805 1.50743Z"
                              fill="#259B35"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import LeftNavigationBar from "./LeftNavigationBar";
import TopNavigationBar from "./TopNavigationBar";
import BroadcastFeed from "./BroadcastFeed";
import CareerProfileFrame from "./CareerProfileFrame";
import axios from "axios";
export default {
  name: "HomeFeedStudentDefault",
  components: {
    LeftNavigationBar,
    TopNavigationBar,
    BroadcastFeed,
    CareerProfileFrame,
  },
  props: [
    "jj",
    "leftNavigationBarProps",
    "topNavigationBarProps",
    "broadcastFeedProps",
    "careerProfileFrameProps",
  ],

  data() {
    return {
      surveys: [],
      tabs: [
        { name: "All Forms", active: true },
        { name: "Skill Forms", active: false },
        { name: "Personality Forms", active: false },
      ],
    };
  },
  mounted() {
    this.fetchSurveys();
  },
  methods: {
    activateTab(index) {
      this.tabs.forEach((tab, tabIndex) => {
        tab.active = index === tabIndex;
      });
    },
    async fetchSurveys() {
      console.log("fetc survyes");
      const serverUrl =
        process.env.VUE_APP_SERVER_URL || "http://localhost:8080"; // Fallback to localhost in case the env var isn't set
      const response = await axios.get(`${serverUrl}/v1/surveys`);
      this.surveys = await response.data.data.items;
      console.log(this.surveys);
    },
  },
};
</script>

<style scoped>
.home-feed-student-default {
  align-items: flex-start;
  background-color: #f3f7f8;
  border: 1px none;
  display: flex;
  gap: 25px;
  height: 100vh;
  overflow: hidden;
  padding: 0 18px;
  position: relative;
  max-width: 1512px;
  width: 100%;
}

@media all and (max-width: 900px) {
  .home-feed-student-default {
    align-items: flex-start;
    background-color: #f3f7f8;
    border: 1px none;
    display: flex;
    gap: 25px;
    height: 982px;
    overflow: hidden;
    padding: 0 18px;
    position: relative;
    max-width: 100vw !important;
  }
}

.flex-col {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 30px;
  min-height: 1579px;
  position: relative;
  width: 100%;
}

.frame-container {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 30px;
  min-height: 927px;
  position: relative;
  right: 0%;
  max-width: 291px;
}

@media all and (max-width: 1400px) {
  .frame-container {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 30px;
    min-height: 927px;
    position: absolute;
    right: 0%;
    max-width: 291px;
  }
}

@media all and (max-width: 600px) {
  .frame-container {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 120px;
    min-height: 927px;
    position: absolute;
    right: 0%;
    max-width: 291px;
  }
}
.frame-2354 {
  align-items: center;
  display: flex;
  margin-right: 12px;
  min-width: 109px;
}

.bell-1 {
  height: 20px;
  width: 20px;
}

.cog-1 {
  height: 20px;
  margin-left: 10px;
  width: 20px;
}

.overlap-group {
  align-items: flex-start;

  border: 1px solid;
  border-color: var(--celeste);
  border-radius: 23px;
  display: flex;
  height: 46px;
  margin-left: 13px;
  min-width: 46px;
  padding: 10px 11px;
}

.jj {
  color: var(--x475f4a);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-m);
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  min-height: 24px;
  white-space: nowrap;
}

.broadcast-feed {
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  height: 1479px;
  width: 100%;
}

.overlap-HomeFeedStudentDefaultgroup1 {
  border-radius: 30px;
  height: 951px;
  position: relative;
  width: 100%;
}

@media all and (max-width: 600px) {
  .overlap-HomeFeedStudentDefaultgroup1 {
    border-radius: 30px;
    min-height: 951px;
    position: relative;
    width: 865px;
    margin-top: 80px;
  }
}

.frame-2342 {
  align-items: flex-start;
  background-color: transparent;
  border-radius: 30px;
  display: inline-flex;
  gap: 10px;
  width: 100%;
  left: 0;
  overflow: hidden;
  overflow-y: scroll;
  padding: 40px 28px;
  position: absolute;
  top: 0;
}

.posts-feeed::-webkit-scrollbar {
  display: none;
}

.chat {
  width: 100%;
  min-height: 700px;
  display: flex;
  align-items: flex-start;

  gap: 20px;
  justify-content: space-between;
}
.chat_users {
  width: 30%;
  background: white;
  height: 100%;
  min-height: 700px;
  max-height: 700px;
  border-radius: 30px;
  padding: 40px 20px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
}

.chat_main {
  width: 100%;
}

.chat_user_heading {
  height: 50px;
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.chat_user_heading h1 {
  color: #000;
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  gap: 8px;
  line-height: normal;
}
.message_headings_icons {
  display: flex;
  align-items: center;
  gap: 4px;
}

.message_headings_icons_search_bar {
  height: 30px;
  width: 30px;
  background: #259b3521;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #259b35;
}
.message_headings_icons_search_bar:nth-child(2) {
  height: 30px;
  width: 30px;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: white;
}

.user_heading {
  display: flex;
  align-items: flex-start;
  gap: 4px;
  margin-top: 30px;
  gap: 8px;
  padding-bottom: 10px;
  border-bottom: 1px solid #259b3521;
}
.user_heading img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}
.user_heading h1 {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.user_heading p {
  color: #000;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.scrollable {
  margin-top: 30px;
  max-height: 570px;
  overflow-y: scroll;
}

.chat_body {
  width: 70%;
  min-height: 700px;
  border-radius: 30px;
  background: white;
  height: 100%;
  padding: 40px 20px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
}

.chat_body_user_heading {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  margin-top: 30px;
  gap: 8px;
  padding-bottom: 10px;
}
.chat_body_user_heading img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}
.chat_body_user_heading h1 {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.chat_body_user_heading p {
  color: #ccc;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.chat_body_user_heading p {
  color: #000;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.chat_body_main {
  max-height: 470px;
  min-height: 470px;
  border-radius: 40px;
  background: #f3f7f8;
  width: 100%;
  margin-top: 10px;
  overflow-y: scroll;
}
.chat_form {
  width: 100%;
  height: 50px;
  background-color: red;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 100px;
  background: #f3f7f8;
}
.chat_form input {
  width: 90%;
  height: 100%;
  border-radius: 100px;
  padding: 0px 20px;
  border: 1px solid transparent;
  background-color: transparent;
}

.chat_form .send_message {
  height: 100%;
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat_form .send_message button {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  flex-shrink: 0;
  justify-content: center;

  align-items: center;
  border: 1px solid transparent;
}

.single_message {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 40px;
  margin-top: 15px;
}

.single_message img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.single_message .message_div {
  padding: 0px 20px;
  height: 100%;
  display: flex;
  align-items: center;
  display: inline-flex;
  color: #475f4a;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: red;
  border-radius: 100px;
  background: rgba(37, 155, 53, 0.13);
}
.chat_body_main {
  padding: 20px 20px;
}

.single_message_right {
  display: flex;
  margin-top: 15px;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  height: 40px;
}

.single_message_right img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.single_message_right .message_div {
  padding: 0px 20px;
  height: 100%;
  display: flex;
  align-items: center;
  display: inline-flex;
  color: #475f4a;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: red;
  border-radius: 100px;
  background: #e4e4e4;
}
</style>
