<template>
  <div class="broadcast-post-3">
    <div class="group-2-2">
      <div class="flex-row-8">
        <img class="ellipse-1-3" :src="ellipse1" alt="Ellipse 1" />
        <div class="flex-col-8">
          <div class="surname-1 inter-medium-black-20px">{{ surname }}</div>
          <div class="posted-10-min-ago-2 inter-normal-celeste-15px">{{ posted10MinAgo }}</div>
        </div>
      </div>
      <div class="flex-col-9">
        <p class="lorem-ipsum-dolor-si-2 inter-light-black-20px">{{ loremIpsumDolorSi }}</p>
        <div class="flex-row-9">
          <img class="flex-row-item-2" :src="sendSvgrepoCom1" alt="send-svgrepo-com 1" /><img
            class="flex-row-item-2"
            :src="envelope1"
            alt="envelope 1"
          /><img class="heart-1-1" :src="heart1" alt="heart 1" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BroadcastPost3",
  props: ["ellipse1", "surname", "posted10MinAgo", "loremIpsumDolorSi", "sendSvgrepoCom1", "envelope1", "heart1"],
};
</script>

<style>
.broadcast-post-3 {
  background-color: var(--white);
  border: 0.5px solid;
  border-color: var(--celeste);
  border-radius: 20px;
  height: 322px;
  position: relative;
  width: 809px;
}

.group-2-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 40px;
  left: 20px;
  min-height: 273px;
  position: relative;
  top: 20px;
  width: 751px;
}

.flex-row-8 {
  align-items: center;
  display: flex;
  gap: 10px;
  min-width: 222px;
}

.ellipse-1-3 {
  height: 61px;
  object-fit: cover;
  width: 61px;
}

.flex-col-8 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 7px;
  min-height: 49px;
  width: 151px;
}

.surname-1 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 24px;
  white-space: nowrap;
}

.posted-10-min-ago-2 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 18px;
  white-space: nowrap;
}

.flex-col-9 {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 26px;
  min-height: 172px;
  width: 745px;
}

.lorem-ipsum-dolor-si-2 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 120px;
  width: 745px;
}

.flex-row-9 {
  align-items: flex-end;
  display: flex;
  gap: 20px;
  height: 26px;
  min-width: 109px;
}

.flex-row-item-2 {
  height: 23px;
  width: 23px;
}

.heart-1-1 {
  align-self: flex-start;
  height: 23px;
  width: 23px;
}
</style>
