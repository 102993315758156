<template>
  <div class="container-center-horizontal">
    <form
      class="home-feed-student-default screen"
      name="form3"
      action="form3"
      method="post"
    >
      <left-navigation-bar v-bind="leftNavigationBarProps" />
      <div class="flex-col">
        <top-navigation-bar />
        <div class="broadcast-feed">
          <div class="overlap-HomeFeedStudentDefaultgroup1">
            <div
              class="frame-2342"
              style="display: flex; align-items: start; flex-direction: column"
            >
              <!-- Phase 2 -->
              <div class="phase_2">
                <div class="three_cards">
                  <div class="education">
                    <p>Career Cluster</p>
                    <h1>Education</h1>
                    <h2>78% Match</h2>
                  </div>
                  <div class="education">
                    <p>Pathway</p>
                    <h1>Training</h1>
                    <h2>88% Match</h2>
                  </div>
                  <div class="education">
                    <p>Goal</p>
                    <h1>2 Year College</h1>
                  </div>
                </div>

                <div class="btns_right">
                  <div data-v-3aa17763="" class="headings">
                    <button data-v-3aa17763="" class="share">
                      <svg
                        data-v-3aa17763=""
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <g data-v-3aa17763="" clip-path="url(#clip0_190_1456)">
                          <path
                            data-v-3aa17763=""
                            d="M17.34 7.59L13.29 11.775C13.14 11.925 12.945 12 12.75 12C12.555 12 12.375 11.9325 12.225 11.79C11.925 11.505 11.9175 11.025 12.21 10.7325L16.065 6.7575H9.75C8.5125 6.7575 7.5 7.77 7.5 9.0075V12.7575C7.5 13.17 7.1625 13.5075 6.75 13.5075C6.3375 13.5075 6 13.17 6 12.7575V9.0075C6 6.9375 7.68 5.2575 9.75 5.2575H16.065L12.21 1.275C11.925 0.974996 11.925 0.502496 12.225 0.217496C12.525 -0.0675042 12.9975 -0.0675042 13.2825 0.232496L17.34 4.425C18.21 5.295 18.21 6.72 17.3325 7.5975L17.34 7.59ZM11.1825 12.8625C10.905 12.5925 9.78 11.1 9 10.05C9 10.05 9 11.55 9 12.66C9 13.77 8.265 14.745 7.2 14.955C5.76 15.2325 4.5 14.1375 4.5 12.75V9C4.5 7.53 5.1075 6.2025 6.0825 5.25H3.75C1.68 5.25 0 6.93 0 9V14.25C0 16.32 1.68 18 3.75 18H9C11.07 18 12.75 16.32 12.75 14.25V13.5C12.165 13.5 11.6025 13.275 11.1825 12.8625Z"
                            fill="#475F4A"
                          ></path>
                        </g>
                        <defs data-v-3aa17763="">
                          <clipPath data-v-3aa17763="" id="clip0_190_1456">
                            <rect
                              data-v-3aa17763=""
                              width="18"
                              height="18"
                              fill="white"
                            ></rect>
                          </clipPath>
                        </defs>
                      </svg>
                      Share</button
                    >
                    <button data-v-3aa17763="" class="share">
                      <svg
                        data-v-3aa17763=""
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="14"
                        viewBox="0 0 16 14"
                        fill="none"
                      >
                        <g data-v-3aa17763="" clip-path="url(#clip0_190_1460)">
                          <path
                            data-v-3aa17763=""
                            d="M10.6667 3.3332C10.6667 3.16094 10.5972 2.99414 10.4722 2.87109L7.75278 0.191406C7.62778 0.0683594 7.45833 0 7.28056 0H7.11111V3.5H10.6667V3.3332ZM15.8611 8.42188L13.2028 5.78594C12.9222 5.50977 12.4417 5.70391 12.4417 6.09492V7.875H10.6639V9.625H12.4417V11.4078C12.4417 11.7988 12.9222 11.993 13.2028 11.7168L15.8611 9.07812C16.0444 8.89766 16.0444 8.60234 15.8611 8.42188ZM5.33333 9.1875V8.3125C5.33333 8.07187 5.53333 7.875 5.77778 7.875H10.6667V4.375H6.88889C6.52222 4.375 6.22222 4.07969 6.22222 3.71875V0H0.666667C0.297222 0 0 0.292578 0 0.65625V13.3438C0 13.7074 0.297222 14 0.666667 14H10C10.3694 14 10.6667 13.7074 10.6667 13.3438V9.625H5.77778C5.53333 9.625 5.33333 9.42813 5.33333 9.1875Z"
                            fill="#475F4A"
                          ></path>
                        </g>
                        <defs data-v-3aa17763="">
                          <clipPath data-v-3aa17763="" id="clip0_190_1460">
                            <rect
                              data-v-3aa17763=""
                              width="16"
                              height="14"
                              fill="white"
                            ></rect>
                          </clipPath>
                        </defs>
                      </svg>
                      Export
                    </button>
                  </div>
                </div>
              </div>
              <div class="grid_div">
                <div class="first_div">
                  <div>
                    <h1>Freshman</h1>
                  </div>

                  <div
                    class="subject_info"
                    v-for="(lang, index) in subjects"
                    :key="index"
                  >
                    <h1 class="light_name">{{ lang.parent }}</h1>
                    <div class="dark_name_and_dropdown">
                      <h1 class="">{{ lang.language }}</h1>
                      <span class="dropdown_relative">
                        <svg
                          @click="setDropdown(index)"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6 ellipse"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                          />
                        </svg>
                        <div
                          :class="
                            dropdown === index
                              ? ' showDropdonw'
                              : 'dropdownMain'
                          "
                        ></div>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="first_div">
                  <div class="plus_icon">
                    <h1>Sophmore</h1>
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 33 33"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_225_3294)">
                          <path
                            d="M16.5 0C13.2366 0 10.0465 0.967708 7.3331 2.78075C4.61969 4.59379 2.50484 7.17074 1.256 10.1857C0.00714922 13.2007 -0.319606 16.5183 0.31705 19.719C0.953706 22.9197 2.52518 25.8597 4.83275 28.1673C7.14031 30.4748 10.0803 32.0463 13.281 32.683C16.4817 33.3196 19.7993 32.9929 22.8143 31.744C25.8293 30.4952 28.4062 28.3803 30.2193 25.6669C32.0323 22.9535 33 19.7634 33 16.5C32.9953 12.1254 31.2554 7.93129 28.162 4.83796C25.0687 1.74464 20.8746 0.00473149 16.5 0ZM22 17.875H17.875V22C17.875 22.3647 17.7301 22.7144 17.4723 22.9723C17.2144 23.2301 16.8647 23.375 16.5 23.375C16.1353 23.375 15.7856 23.2301 15.5277 22.9723C15.2699 22.7144 15.125 22.3647 15.125 22V17.875H11C10.6353 17.875 10.2856 17.7301 10.0277 17.4723C9.76987 17.2144 9.62501 16.8647 9.62501 16.5C9.62501 16.1353 9.76987 15.7856 10.0277 15.5277C10.2856 15.2699 10.6353 15.125 11 15.125H15.125V11C15.125 10.6353 15.2699 10.2856 15.5277 10.0277C15.7856 9.76987 16.1353 9.625 16.5 9.625C16.8647 9.625 17.2144 9.76987 17.4723 10.0277C17.7301 10.2856 17.875 10.6353 17.875 11V15.125H22C22.3647 15.125 22.7144 15.2699 22.9723 15.5277C23.2301 15.7856 23.375 16.1353 23.375 16.5C23.375 16.8647 23.2301 17.2144 22.9723 17.4723C22.7144 17.7301 22.3647 17.875 22 17.875Z"
                            fill="#259B35"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_225_3294">
                            <rect width="33" height="33" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  </div>

                  <div
                    class="subject_info"
                    v-for="(lang, index) in subjects2"
                    :key="index"
                  >
                    <h1 class="light_name">{{ lang.parent }}</h1>
                    <div class="dark_name_and_dropdown">
                      <h1 class="">{{ lang.language }}</h1>
                      <span class="dropdown_relative">
                        <svg
                          @click="setDropdown(index)"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6 ellipse"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                          />
                        </svg>
                        <div
                          :class="
                            dropdown === index
                              ? ' showDropdonw'
                              : 'dropdownMain'
                          "
                        ></div>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="first_div">
                  <div class="plus_icon">
                    <h1>Junior</h1>
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 33 33"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_225_3294)">
                          <path
                            d="M16.5 0C13.2366 0 10.0465 0.967708 7.3331 2.78075C4.61969 4.59379 2.50484 7.17074 1.256 10.1857C0.00714922 13.2007 -0.319606 16.5183 0.31705 19.719C0.953706 22.9197 2.52518 25.8597 4.83275 28.1673C7.14031 30.4748 10.0803 32.0463 13.281 32.683C16.4817 33.3196 19.7993 32.9929 22.8143 31.744C25.8293 30.4952 28.4062 28.3803 30.2193 25.6669C32.0323 22.9535 33 19.7634 33 16.5C32.9953 12.1254 31.2554 7.93129 28.162 4.83796C25.0687 1.74464 20.8746 0.00473149 16.5 0ZM22 17.875H17.875V22C17.875 22.3647 17.7301 22.7144 17.4723 22.9723C17.2144 23.2301 16.8647 23.375 16.5 23.375C16.1353 23.375 15.7856 23.2301 15.5277 22.9723C15.2699 22.7144 15.125 22.3647 15.125 22V17.875H11C10.6353 17.875 10.2856 17.7301 10.0277 17.4723C9.76987 17.2144 9.62501 16.8647 9.62501 16.5C9.62501 16.1353 9.76987 15.7856 10.0277 15.5277C10.2856 15.2699 10.6353 15.125 11 15.125H15.125V11C15.125 10.6353 15.2699 10.2856 15.5277 10.0277C15.7856 9.76987 16.1353 9.625 16.5 9.625C16.8647 9.625 17.2144 9.76987 17.4723 10.0277C17.7301 10.2856 17.875 10.6353 17.875 11V15.125H22C22.3647 15.125 22.7144 15.2699 22.9723 15.5277C23.2301 15.7856 23.375 16.1353 23.375 16.5C23.375 16.8647 23.2301 17.2144 22.9723 17.4723C22.7144 17.7301 22.3647 17.875 22 17.875Z"
                            fill="#259B35"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_225_3294">
                            <rect width="33" height="33" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  </div>

                  <div
                    class="subject_info"
                    v-for="(lang, index) in subjects"
                    :key="index"
                  >
                    <h1 class="light_name">{{ lang.parent }}</h1>
                    <div class="dark_name_and_dropdown">
                      <h1 class="">{{ lang.language }}</h1>
                      <span class="dropdown_relative">
                        <svg
                          @click="setDropdown(index)"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6 ellipse"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                          />
                        </svg>
                        <div
                          :class="
                            dropdown === index
                              ? ' showDropdonw'
                              : 'dropdownMain'
                          "
                        ></div>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="first_div">
                  <div class="plus_icon">
                    <h1>Senior</h1>
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 33 33"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_225_3294)">
                          <path
                            d="M16.5 0C13.2366 0 10.0465 0.967708 7.3331 2.78075C4.61969 4.59379 2.50484 7.17074 1.256 10.1857C0.00714922 13.2007 -0.319606 16.5183 0.31705 19.719C0.953706 22.9197 2.52518 25.8597 4.83275 28.1673C7.14031 30.4748 10.0803 32.0463 13.281 32.683C16.4817 33.3196 19.7993 32.9929 22.8143 31.744C25.8293 30.4952 28.4062 28.3803 30.2193 25.6669C32.0323 22.9535 33 19.7634 33 16.5C32.9953 12.1254 31.2554 7.93129 28.162 4.83796C25.0687 1.74464 20.8746 0.00473149 16.5 0ZM22 17.875H17.875V22C17.875 22.3647 17.7301 22.7144 17.4723 22.9723C17.2144 23.2301 16.8647 23.375 16.5 23.375C16.1353 23.375 15.7856 23.2301 15.5277 22.9723C15.2699 22.7144 15.125 22.3647 15.125 22V17.875H11C10.6353 17.875 10.2856 17.7301 10.0277 17.4723C9.76987 17.2144 9.62501 16.8647 9.62501 16.5C9.62501 16.1353 9.76987 15.7856 10.0277 15.5277C10.2856 15.2699 10.6353 15.125 11 15.125H15.125V11C15.125 10.6353 15.2699 10.2856 15.5277 10.0277C15.7856 9.76987 16.1353 9.625 16.5 9.625C16.8647 9.625 17.2144 9.76987 17.4723 10.0277C17.7301 10.2856 17.875 10.6353 17.875 11V15.125H22C22.3647 15.125 22.7144 15.2699 22.9723 15.5277C23.2301 15.7856 23.375 16.1353 23.375 16.5C23.375 16.8647 23.2301 17.2144 22.9723 17.4723C22.7144 17.7301 22.3647 17.875 22 17.875Z"
                            fill="#259B35"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_225_3294">
                            <rect width="33" height="33" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  </div>

                  <div
                    class="subject_info"
                    v-for="(lang, index) in subjects"
                    :key="index"
                  >
                    <h1 class="light_name">{{ lang.parent }}</h1>
                    <div class="dark_name_and_dropdown">
                      <h1 class="">{{ lang.language }}</h1>
                      <span class="dropdown_relative">
                        <svg
                          @click="setDropdown(index)"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6 ellipse"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                          />
                        </svg>
                        <div
                          :class="
                            dropdown === index
                              ? ' showDropdonw'
                              : 'dropdownMain'
                          "
                        ></div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import LeftNavigationBar from "./LeftNavigationBar";
import TopNavigationBar from "./TopNavigationBar";
import BroadcastFeed from "./BroadcastFeed";
import CareerProfileFrame from "./CareerProfileFrame";
import Accordion from "./Accordion.vue";
import axios from "axios";
export default {
  name: "HomeFeedStudentDefault",
  components: {
    LeftNavigationBar,
    TopNavigationBar,
    BroadcastFeed,
    CareerProfileFrame,
    Accordion,
  },
  props: [
    "jj",
    "leftNavigationBarProps",
    "topNavigationBarProps",
    "broadcastFeedProps",
    "careerProfileFrameProps",
  ],

  data() {
    return {
      surveys: [],
      classes: [],
      dropdown: null,
      subjects: [
        { parent: "English", language: "English" },
        { parent: "Mathematics", language: "Mathematics" },
        { parent: "Science", language: "Physics" },
        { parent: "Science", language: "Chemistry" },
        { parent: "Science", language: "Biology" },
        { parent: "Social Studies", language: "History" },
        { parent: "Social Studies", language: "Geography" },
        { parent: "Social Studies", language: "Civics" },
        { parent: "Languages", language: "French" },
        { parent: "Languages", language: "Spanish" },
      ],
      subjects2: [
        { parent: "English", language: "English" },
        { parent: "Mathematics", language: "Mathematics" },
        { parent: "Science", language: "Physics" },
        { parent: "Science", language: "Chemistry" },
        { parent: "Languages", language: "French" },
        { parent: "Languages", language: "Spanish" },
      ],

      tabs: [
        { name: "All Forms", active: true },
        { name: "Skill Forms", active: false },
        { name: "Personality Forms", active: false },
      ],
    };
  },
  mounted() {
    this.fetchClasses();
  },
  methods: {
    setDropdown(index) {
      if (this.dropdown === index) {
        this.dropdown = null;
      } else {
        this.dropdown = index;
      }
    },
    activateTab(index) {
      this.tabs.forEach((tab, tabIndex) => {
        tab.active = index === tabIndex;
      });
    },
    async fetchClasses() {
      console.log("fetch classes");
      const serverUrl =
        process.env.VUE_APP_SERVER_URL || "http://localhost:8080"; // Fallback to localhost in case the env var isn't set
      const response = await axios.get(`${serverUrl}/v1/classess`);
      this.classes = await response.data.$data;
      console.log(this.surveys);
    },
  },
};
</script>

<style scoped>
.subject_info {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.light_name {
  color: #a5a5a5 !important;
  font-family: Inter;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.dark_name_and_dropdown {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home-feed-student-default {
  align-items: flex-start;
  background-color: #f3f7f8;
  border: 1px none;
  display: flex;
  gap: 25px;
  min-height: 100vh;
  overflow: hidden;
  padding: 0 18px;
  position: relative;
  max-width: 1512px;
  width: 100%;
}

.phase_2 {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 30px;
  width: 100%;
}
.three_cards {
  width: 50%;
  height: 180px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.three_cards .education {
  width: 33%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.three_cards .education {
  width: 33%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.map {
  width: 33%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.three_cards .education p {
  color: #a6a6a6;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.three_cards .education h1 {
  color: #259b35;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.three_cards .education h2 {
  color: #475f4a;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.three_cards .education button {
  border-radius: 100px;
  background: #259b35;
  width: 191px;

  color: #fff;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  border: 1px solid transparent;
  line-height: normal;
  height: 36px;
  flex-shrink: 0;
}

@media all and (max-width: 1000px) {
  .three_cards {
    width: 100% !important;
    height: 180px;

    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .single_card {
    width: 100%;
    height: 191px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 0.5px solid #475f4a;
    background: #fff;
    flex-shrink: 0;
    padding: 20px 40px;
  }

  .phase_2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 70px;
    gap: 100px;
  }
}

.single_card label {
  color: #000;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.input_field {
  display: grid;
  margin-top: 20px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem;
}

.main_check {
  display: flex;
  align-items: center;
  gap: 8px;
}
.main_check label {
  color: #000;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media all and (max-width: 600px) {
  .three_cards {
    width: 100% !important;
    height: 500px;
    gap: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
  }

  .single_card {
    width: 100%;
    height: 280px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 0.5px solid #475f4a;
    background: #fff;
    flex-shrink: 0;
    padding: 20px 40px;
  }

  .phase_2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 70px;
    gap: 100px;
  }
}

.posts-feeed_two {
  align-items: flex-start;
  display: flex;
  gap: 30px;
  margin-top: 40px;

  flex-wrap: wrap;

  overflow-x: scroll;
  position: relative;
  width: 100%;
}

.feed_card {
  border-radius: 20px;
  border: 0.5px solid #ccc;
  background: #fff;
  padding: 30px 20px;
  width: 100%;
}

.headings_card {
  display: flex;
  align-items: center;
  gap: 8px;
}

.heading_image img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.heading_texts h1 {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.heading_texts p {
  color: #ccc;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  margin-top: 2px;
  line-height: normal;
}

.paragraph_card {
  margin-top: 20px;
  margin-bottom: 30px;
}
.paragraph_card p {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.card_buttons {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.card_buttons .link {
  padding: 10px 20px !important;
  border-radius: 100px;
  background: #259b35 !important;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  display: block;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-decoration: none;
  margin-left: auto;
}

.card_social {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 20px;
  justify-content: flex-end;
}

/* Base Styles */
.p-4 {
  padding: 20px 10px;
  border-radius: 10px;
}

.recent-survey {
  padding: 0.5rem 0.75rem;

  display: inline-flex;
}

.recent-survey-main {
  display: flex;
  flex-direction: column;
}

.recent-survey-heading {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
}

.recent-survey-heading img {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
}

.recent-survey-heading h1 {
  margin: 0;
  color: #475f4a;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@media all and (max-width: 900px) {
  .home-feed-student-default {
    align-items: flex-start;
    background-color: #f3f7f8;
    border: 1px none;
    display: flex;
    gap: 25px;
    height: 982px;
    overflow: hidden;
    padding: 0 18px;
    position: relative;
    max-width: 100vw !important;
  }
}

.flex-col {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 30px;
  min-height: 1579px;
  position: relative;
  width: 100%;
}

.frame-container {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 30px;
  min-height: 927px;
  position: relative;
  right: 0%;
  max-width: 291px;
}

@media all and (max-width: 1400px) {
  .frame-container {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 30px;
    min-height: 927px;
    position: absolute;
    right: 0%;
    max-width: 291px;
  }
}

@media all and (max-width: 600px) {
  .frame-container {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 120px;
    min-height: 927px;
    position: absolute;
    right: 0%;
    max-width: 291px;
  }
}
.frame-2354 {
  align-items: center;
  display: flex;
  margin-right: 12px;
  min-width: 109px;
}

.bell-1 {
  height: 20px;
  width: 20px;
}

.cog-1 {
  height: 20px;
  margin-left: 10px;
  width: 20px;
}

.overlap-group {
  align-items: flex-start;

  border: 1px solid;
  border-color: var(--celeste);
  border-radius: 23px;
  display: flex;
  height: 46px;
  margin-left: 13px;
  min-width: 46px;
  padding: 10px 11px;
}

.jj {
  color: var(--x475f4a);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-m);
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  min-height: 24px;
  white-space: nowrap;
}

.broadcast-feed {
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  height: 1479px;
  width: 100%;
}

.overlap-HomeFeedStudentDefaultgroup1 {
  border-radius: 30px;
  height: 951px;
  position: relative;
  width: 100%;
}

@media all and (max-width: 600px) {
  .overlap-HomeFeedStudentDefaultgroup1 {
    border-radius: 30px;
    min-height: 951px;
    position: relative;
    width: 865px;
    margin-top: 80px;
  }
}

.frame-2342 {
  align-items: flex-start;
  background-color: transparent;
  border-radius: 30px;
  display: inline-flex;
  gap: 10px;
  width: 100%;
  background-color: white;
  left: 0;
  overflow: hidden;
  height: 100%;
  overflow-y: scroll;
  padding: 40px 28px;
  position: absolute;
  top: 0;
}

.posts-feeed::-webkit-scrollbar {
  display: none;
}

.grid_div {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 40px;
  width: 100%;
}

.first_div {
  border-radius: 10px;
  padding: 20px 12px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
}

.first_div div h1 {
  color: #000;
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.personal_info {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 60px;
}

.personal_info div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.personal_info div p {
  color: #000;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.personal_info .title_t {
  color: #a5ac9d;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;

  display: inline-flex;
  font-weight: 500;
  line-height: normal;
}

.flex_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.second p {
  color: #000;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.academics {
  margin-top: 30px;
}
.academics h1 {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.exams {
  display: flex;
  align-items: center;
  margin-top: 20px;
  justify-content: space-between;
}
.exams_one {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;
}

.exams_one h1 {
  color: #475f4a;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.exams_one p {
  color: #636060;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.abcd {
  display: flex;
  align-items: center;
  gap: 8px;
}

.abcd p {
  color: #636060;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.abcd_para {
  color: #000;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.abcde {
  display: flex;
  align-items: flex-start;
  margin-top: 30px;
  flex-direction: column;
  gap: 10px;
}

.second {
  border-radius: 10px;
  background: #fff;
  padding: 8px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
}

.btns {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 14px;
}
.single_btn {
  color: #475f4a;
  font-family: Inter;
  font-size: 15px;

  border-radius: 100px;
  display: inline-flex;
  padding: 5px 20px;
  align-items: flex-start;
  gap: 10px;
  background: #f0f4f0;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media all and (max-width: 1000px) {
  .grid_div {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 40px;
    width: 100%;
  }
}

@media all and (max-width: 600px) {
  .grid_div {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 40px;
    width: 100%;
  }
}

.ellipse {
  height: 16px;
  widows: 16px;
  cursor: pointer;
}
.dark_name_and_dropdown {
  margin-top: 5px;
}
.dropdown_relative {
  position: relative;
}
.dropdownMain {
  position: absolute;
  top: 100%;
  right: 0%;
  display: none;
  height: 60px;
  width: 60px;
  border-radius: 5px;

  background: #0421b0;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
}
.showDropdonw {
  position: absolute;
  top: 100%;
  right: 0%;
  display: block;
  height: 60px;
  z-index: 999;
  width: 60px;
  border-radius: 5px;
  background: white;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
}
.plus_icon {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headings 
{
  display: flex;
  align-items: center;
  gap: 10px;
}

.headings .share {
    border-radius: 100px;
    background: #f0f4f0;
    display: inline-flex;
    padding: 5px 20px;
    align-items: flex-start;
    color: #475f4a;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    gap: 10px;
    border: 1px solid transparent;
}
</style>
