<template>
  <div class="feed_card">
    <div class="">
      <div class="headings_card">
       <div class="heading_texts">
            <h1>{{ surname }}</h1>
          <p>{{ posted10MinAgo }}</p>
        </div>
      </div>
      <div class="paragraph_card"  >
        <!-- Paragraph -->
        <p v-html="message">
          {{ message }}
        </p>
      </div>
      <!-- Buttons -->
      <div class="card_buttons">
        <!-- Socia Icons -->
        <div class="card_social">
          <a href="#">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23"
              height="23"
              viewBox="0 0 23 23"
              fill="none"
            >
              <path
                d="M7.65308 21.2197C7.65308 21.3575 7.7296 21.4838 7.85129 21.5482C7.97289 21.6126 8.12074 21.6043 8.23439 21.5265L10.7941 19.7787L7.65312 18.2816V21.2197H7.65308Z"
                fill="#259B35"
              />
              <path
                d="M22.8805 1.50743C22.8102 1.44261 22.7194 1.40869 22.6277 1.40869C22.5724 1.40869 22.5172 1.42096 22.4651 1.44661L0.367267 12.2862C0.141403 12.3972 -0.00152111 12.6278 1.22156e-05 12.8801C0.00154554 13.1321 0.146886 13.3612 0.374376 13.4697L6.19831 16.2448L17.8432 6.67357L7.64126 16.9323L14.8964 20.3892C14.9856 20.4319 15.0829 20.4531 15.1791 20.4531C15.2613 20.4531 15.3426 20.4382 15.4201 20.4077C15.5891 20.3409 15.7234 20.2071 15.7904 20.0381L22.9736 1.91697C23.0305 1.77437 22.9934 1.61132 22.8805 1.50743Z"
                fill="#259B35"
              />
            </svg>
          </a>
          <a href="#">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23"
              height="23"
              viewBox="0 0 23 23"
              fill="none"
            >
              <path
                d="M22.5643 8.57109C22.7395 8.43184 23 8.56211 23 8.78223V17.9688C23 19.1592 22.0342 20.125 20.8438 20.125H2.15625C0.96582 20.125 0 19.1592 0 17.9688V8.78672C0 8.56211 0.256055 8.43633 0.435742 8.57559C1.44199 9.35723 2.77617 10.35 7.3582 13.6787C8.30606 14.3705 9.90527 15.826 11.5 15.817C13.1037 15.8305 14.7344 14.3436 15.6463 13.6787C20.2283 10.35 21.558 9.35273 22.5643 8.57109ZM11.5 14.375C12.5422 14.393 14.0426 13.0633 14.7973 12.5152C20.7584 8.18926 21.2121 7.81191 22.5867 6.73379C22.8473 6.53164 23 6.21719 23 5.88477V5.03125C23 3.84082 22.0342 2.875 20.8438 2.875H2.15625C0.96582 2.875 0 3.84082 0 5.03125V5.88477C0 6.21719 0.152734 6.52715 0.413281 6.73379C1.78789 7.80742 2.2416 8.18926 8.20273 12.5152C8.95742 13.0633 10.4578 14.393 11.5 14.375Z"
                fill="#259B35"
              />
            </svg>
          </a>
          <a href="#">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23"
              height="23"
              viewBox="0 0 23 23"
              fill="none"
            >
              <g clip-path="url(#clip0_635_73)">
                <path
                  d="M2.23256 2.81662C4.6808 0.73225 8.34193 1.07815 10.615 3.42307L11.4999 4.33948L12.3849 3.42756C14.2177 1.53186 17.9148 0.390844 20.7673 2.81662C23.5884 5.22444 23.7367 9.54592 21.212 12.1514L12.5197 21.1268C12.2411 21.4143 11.8728 21.5625 11.5044 21.5625C11.1361 21.5625 10.7677 21.4188 10.4892 21.1268L1.79681 12.1514C-0.736782 9.54592 -0.588539 5.22444 2.23256 2.81662ZM2.82103 11.1541L11.482 20.1295L20.1788 11.1541C21.9038 9.37522 22.2632 5.98362 19.8329 3.91272C17.3712 1.81037 14.4783 3.33323 13.4181 4.42932L11.4999 6.41037L9.58177 4.42932C8.53959 3.35119 5.61966 1.82385 3.16693 3.91272C0.741148 5.97912 1.09603 9.37073 2.82103 11.1541Z"
                  fill="#259B35"
                />
              </g>
              <defs>
                <clipPath id="clip0_635_73">
                  <rect
                    width="23"
                    height="23"
                    fill="white"
                    transform="matrix(-1 0 0 1 23 0)"
                  />
                </clipPath>
              </defs></svg
          ></a>
        </div>
      </div>
    </div>
  </div>

  
</template>

<script>
export default {
  name: "BroadcastPost1",
  props: ["logo", "surname", "posted10MinAgo", "message", "learnMore"],
};
</script>

<style>
.broadcast-post-1 {
  background-color: var(--white);
  border: 0.5px solid;
  border-color: var(--celeste);
  border-radius: 20px;
  height: 372px;
  position: relative;
  width: 809px;
}

.group-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 40px;
  left: 20px;
  min-height: 328px;
  position: relative;
  top: 20px;
  width: 751px;
}

.flex-row-4 {
  align-items: center;
  display: flex;
  gap: 10px;
  min-width: 222px;
}

.ellipse-1-1 {
  height: 61px;
  object-fit: cover;
  width: 61px;
}

.flex-col-5 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 7px;
  min-height: 49px;
  width: 151px;
}

.surname {
  letter-spacing: 0;
  line-height: normal;
  min-height: 24px;
  white-space: nowrap;
}

.posted-10-min-ago {
  letter-spacing: 0;
  line-height: normal;
  min-height: 18px;
  white-space: nowrap;
}

.broadcast-post1-flex-col-6 {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 21px;
  min-height: 227px;
  width: 745px;
}

.lorem-ipsum-dolor-si {
  letter-spacing: 0;
  line-height: normal;
  min-height: 120px;
  width: 745px;
}

.overlap-group-5 {
  align-items: center;
  background-color: var(--style);
  border-radius: 100px;
  display: flex;
  height: 42px;
  min-width: 141px;
  padding: 0 16px;
}

.learn-more {
  letter-spacing: 0;
  line-height: normal;
  min-height: 24px;
  min-width: 109px;
  text-align: center;
  white-space: nowrap;
}

.flex-row-5 {
  align-items: flex-start;
  display: flex;
  gap: 20px;
  min-width: 109px;
}

.flex-row-item {
  height: 23px;
  width: 23px;
}


.feed_card {
  border-radius: 20px;
  border: 0.5px solid #ccc;
  background: #fff;
  padding: 30px 20px;
  width: 100%;
}

.headings_card {
  display: flex;
  align-items: center;
  gap: 8px;
}

.heading_image img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.heading_texts h1 {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.heading_texts p {
  color: #ccc;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  margin-top: 2px;
  line-height: normal;
}

.paragraph_card {
  margin-top: 20px;
  margin-bottom: 30px;
}
.paragraph_card p {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.card_buttons {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.card_buttons .link {
  padding: 10px 20px !important;
  border-radius: 100px;
  background: #259b35 !important;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  display: block;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-decoration: none;
  margin-left: auto;
}

.card_social {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 20px;
  justify-content: flex-end;
}
</style>
