<template>
  <div class="broadcast-post-2">
    <div class="group-2-1">
      <div class="flex-row-6">
        <div class="ellipse-1-2"></div>
        <div class="flex-col-7">
          <div class="what-is-access-point inter-medium-black-20px">{{ whatIsAccessPoint }}</div>
          <div class="posted-10-min-ago-1 inter-normal-celeste-15px">{{ posted10MinAgo }}</div>
        </div>
      </div>
      <img class="rectangle-8" :src="rectangle8" alt="Rectangle 8" />
      <div class="group-5">
        <p class="lorem-ipsum-dolor-si-1 inter-normal-black-20px">{{ loremIpsumDolorSi }}</p>
        <div class="flex-row-7">
          <img class="flex-row-item-1" :src="sendSvgrepoCom1" alt="send-svgrepo-com 1" /><img
            class="flex-row-item-1"
            :src="envelope1"
            alt="envelope 1"
          /><img class="heart-1" :src="heart1" alt="heart 1" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BroadcastPost2",
  props: [
    "whatIsAccessPoint",
    "posted10MinAgo",
    "rectangle8",
    "loremIpsumDolorSi",
    "sendSvgrepoCom1",
    "envelope1",
    "heart1",
  ],
};
</script>

<style>
.broadcast-post-2 {
  background-color: var(--white);
  border: 0.5px solid;
  border-color: var(--celeste);
  border-radius: 20px;
  height: 595px;
  position: relative;
  width: 809px;
}

.group-2-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  left: 20px;
  min-height: 552px;
  position: relative;
  top: 20px;
  width: 749px;
}

.flex-row-6 {
  align-items: flex-start;
  display: flex;
  gap: 10px;
  min-width: 283px;
}

.ellipse-1-2 {
  background-color: #fafafa;
  border-radius: 30.5px;
  height: 61px;
  width: 61px;
}

.flex-col-7 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 13px;
  min-height: 55px;
  width: 212px;
}

.what-is-access-point {
  letter-spacing: 0;
  line-height: normal;
  min-height: 24px;
  white-space: nowrap;
}

.posted-10-min-ago-1 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 18px;
  white-space: nowrap;
}

.rectangle-8 {
  height: 279px;
  object-fit: cover;
  width: 734px;
}

.group-5 {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 26px;
  min-height: 172px;
  width: 747px;
}

.lorem-ipsum-dolor-si-1 {
  letter-spacing: 0;
  line-height: normal;
  margin-right: 2px;
  min-height: 120px;
  width: 745px;
}

.flex-row-7 {
  align-items: flex-end;
  display: flex;
  gap: 20px;
  height: 26px;
  margin-right: 2px;
  min-width: 109px;
}

.flex-row-item-1 {
  height: 23px;
  width: 23px;
}

.heart-1 {
  align-self: flex-start;
  height: 23px;
  width: 23px;
}
</style>
