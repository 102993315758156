import Vue from "vue";
import Router from "vue-router";
import StudentLoginPage from "./components/StudentLoginPage";
import { studentLoginPageData } from "./dataStudentLogin";
import HomeFeedStudentDefault from "./components/HomeFeedStudentDefault";
import { homeFeedStudentDefaultData } from "./dataHomeFeedStudentDefault";

import SurveyDefault from "./components/SurveyDefault";
import Message from "./components/Messages.vue";
import CareerDefault from "./components/CareerDefault.vue";
import ResearchDefault from "./components/ResearchDefault.vue";
import ResumeDefault from './components/ResumeDefault.vue';
import FourYearsPlanDefault from './components/FourYearsPlanDefault.vue';
import ProviderBroadcast from './components/ProviderPages/ProviderBroadcast'
import SingleBroadcast from './components/ProviderPages/SingleBroadcast'
import ProviderProfile from './components/ProviderPages/ProviderProfile';

Vue.use(Router);

const router = new Router({
  mode: 'hash',
  routes: [
    {
      path: "*",
      component: StudentLoginPage,
      props: { ...studentLoginPageData },
      meta: {title: 'ScholarPath' }
    },
    {
      path: "/homeFeed",
      component: HomeFeedStudentDefault,
      props: {
        jj: "JJ",
        leftNavigationBarProps:
          homeFeedStudentDefaultData.leftNavigationBarProps,
        topNavigationBarProps: homeFeedStudentDefaultData.topNavigationBarProps,
        broadcastFeedProps: homeFeedStudentDefaultData.broadcastFeedProps,
        careerProfileFrameProps:
          homeFeedStudentDefaultData.careerProfileFrameProps,
      },

    },
    {
      path: "/surveyDefault",
      component: SurveyDefault,
      props: {
        jj: "JJ",
        leftNavigationBarProps:
          homeFeedStudentDefaultData.leftNavigationBarProps,
        topNavigationBarProps: homeFeedStudentDefaultData.topNavigationBarProps,
        broadcastFeedProps: homeFeedStudentDefaultData.broadcastFeedProps,
        careerProfileFrameProps:
          homeFeedStudentDefaultData.careerProfileFrameProps,
      },
    },

    {
      path: "/messages",
      component: Message,
      props: {
        jj: "JJ",
        leftNavigationBarProps:
          homeFeedStudentDefaultData.leftNavigationBarProps,
        topNavigationBarProps: homeFeedStudentDefaultData.topNavigationBarProps,
        broadcastFeedProps: homeFeedStudentDefaultData.broadcastFeedProps,
        careerProfileFrameProps:
          homeFeedStudentDefaultData.careerProfileFrameProps,
      },
    },

    {
      path: "/researchDefault",
      component: ResearchDefault,
      props: {
        jj: "JJ",
        leftNavigationBarProps:
          homeFeedStudentDefaultData.leftNavigationBarProps,
        topNavigationBarProps: homeFeedStudentDefaultData.topNavigationBarProps,
        broadcastFeedProps: homeFeedStudentDefaultData.broadcastFeedProps,
        careerProfileFrameProps:
          homeFeedStudentDefaultData.careerProfileFrameProps,
      },
    },

    {
      path: "/resumeDefault",
      component: ResumeDefault,
      props: {
        jj: "JJ",
        leftNavigationBarProps:
          homeFeedStudentDefaultData.leftNavigationBarProps,
        topNavigationBarProps: homeFeedStudentDefaultData.topNavigationBarProps,
        broadcastFeedProps: homeFeedStudentDefaultData.broadcastFeedProps,
        careerProfileFrameProps:
          homeFeedStudentDefaultData.careerProfileFrameProps,
      },
    },

    {
      path: "/fouryearsplan",
      component: FourYearsPlanDefault,
      props: {
        jj: "JJ",
        leftNavigationBarProps:
          homeFeedStudentDefaultData.leftNavigationBarProps,
        topNavigationBarProps: homeFeedStudentDefaultData.topNavigationBarProps,
        broadcastFeedProps: homeFeedStudentDefaultData.broadcastFeedProps,
        careerProfileFrameProps:
          homeFeedStudentDefaultData.careerProfileFrameProps,
      },
    },

    {
      path: "/careersDefault",
      component: CareerDefault,
    },
    {
      path: "/providerBroadcast",
      component: ProviderBroadcast,
    },
    {
      path: "/singleBroadcast",
      component: SingleBroadcast,
    },
    {
      path: "/providerProfile",
      component: ProviderProfile,
    },
  ],
});

// Navigation guard
//   router.beforeEach((to, from, next) => {
  //   if (to.matched.some(record => record.meta.requiresAuth) && !authService.isLoggedIn) {
    //   next({ path: '/' });  // Redirect to the login page if the user is not authenticated
    // } else {
      // next();
    // }
//});

export default router;
