<template>
  <div :class="[`jobs-tab`, className || ``]">
    <div class="all-1 inter-normal-white-20px">{{ all }}</div>
  </div>
</template>

<script>
export default {
  name: "HomeFeedStudentDefaultFrame23372",
  props: ["all", "className"],
};
</script>

<style>
.jobs-tab {
  align-items: flex-start;
  background-color: var(--bud);
  border-radius: 100px;
  display: inline-flex;
  gap: 10px;
  left: 129px;
  padding: 3px 28px;
  position: absolute;
  top: 30px;
}

.all-1 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.jobs-tab.scholarships-tab {
  left: 253px;
}

.jobs-tab.careers-tab {
  left: 450px;
}


@media all and (max-width: 800px){
  .jobs-tab.scholarships-tab {
  left: 30px;
  top: 60px;
}
.jobs-tab.careers-tab {
  left: 200px;
  top: 60px;
}

}
</style>
