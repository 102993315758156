<template>
  <div class="container-center-horizontal">
    <form
      class="home-feed-student-default screen"
      name="form3"
      action="form3"
      method="post"
    >
      <left-navigation-bar v-bind="leftNavigationBarProps" />
      <div class="flex-col">
        <top-navigation-bar />
        <div class="broadcast-feed">
          <div class="overlap-HomeFeedStudentDefaultgroup1">
            <div
              class="frame-2342"
              style="display: flex; align-items: start; flex-direction: column"
            >
              <!--Chat-->
              <div class="chat_main">
                <div class="chat_form">
                  <input placeholder="Browse Topics and Careers" />
                  <div class="send_message">
                    <button>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_125_927)">
                          <path
                            d="M17.877 16.9312L13.3418 12.3961C13.2609 12.3152 13.1555 12.273 13.043 12.273H12.6809C13.8867 10.9688 14.625 9.22852 14.625 7.3125C14.625 3.27305 11.352 0 7.3125 0C3.27305 0 0 3.27305 0 7.3125C0 11.352 3.27305 14.625 7.3125 14.625C9.22852 14.625 10.9688 13.8867 12.273 12.6844V13.043C12.273 13.1555 12.3188 13.2609 12.3961 13.3418L16.9312 17.877C17.0965 18.0422 17.3637 18.0422 17.5289 17.877L17.877 17.5289C18.0422 17.3637 18.0422 17.0965 17.877 16.9312ZM7.3125 13.5C3.8918 13.5 1.125 10.7332 1.125 7.3125C1.125 3.8918 3.8918 1.125 7.3125 1.125C10.7332 1.125 13.5 3.8918 13.5 7.3125C13.5 10.7332 10.7332 13.5 7.3125 13.5Z"
                            fill="#259B35"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_125_927">
                            <rect width="18" height="18" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>

              <!-- Cards -->

              <div class="cards">
              <!-- Single Card -->
                <div class="single_card_body">
                  <div class="card_headings">
                    <img
                      src="https://plus.unsplash.com/premium_photo-1661914978519-52a11fe159a7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=735&q=80"
                      class=""
                    />
                    <h1>Jobs</h1>
                  </div>

                  <div class="card_description">
                    <p>
                    Summary of this section as a resource...Lorem ipsum dolor
                    sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi
                    ut aliquip ex ea commodo consequat
                    </p>
                  </div>
                </div>
                <div class="single_card_body">
                  <div class="card_headings">
                    <img
                      src="https://plus.unsplash.com/premium_photo-1661914978519-52a11fe159a7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=735&q=80"
                      class=""
                    />
                    <h1>Jobs</h1>
                  </div>

                  <div class="card_description">
                    <p>
                    Summary of this section as a resource...Lorem ipsum dolor
                    sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi
                    ut aliquip ex ea commodo consequat
                    </p>
                  </div>
                </div>
                <div class="single_card_body">
                  <div class="card_headings">
                    <img
                      src="https://plus.unsplash.com/premium_photo-1661914978519-52a11fe159a7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=735&q=80"
                      class=""
                    />
                    <h1>Jobs</h1>
                  </div>

                  <div class="card_description">
                    <p>
                    Summary of this section as a resource...Lorem ipsum dolor
                    sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi
                    ut aliquip ex ea commodo consequat
                    </p>
                  </div>
                </div>
                <div class="single_card_body">
                  <div class="card_headings">
                    <img
                      src="https://plus.unsplash.com/premium_photo-1661914978519-52a11fe159a7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=735&q=80"
                      class=""
                    />
                    <h1>Jobs</h1>
                  </div>

                  <div class="card_description">
                    <p>
                    Summary of this section as a resource...Lorem ipsum dolor
                    sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi
                    ut aliquip ex ea commodo consequat
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import LeftNavigationBar from "./LeftNavigationBar";
import TopNavigationBar from "./TopNavigationBar";
import BroadcastFeed from "./BroadcastFeed";
import CareerProfileFrame from "./CareerProfileFrame";
import axios from "axios";
export default {
  name: "HomeFeedStudentDefault",
  components: {
    LeftNavigationBar,
    TopNavigationBar,
    BroadcastFeed,
    CareerProfileFrame,
  },
  props: [
    "jj",
    "leftNavigationBarProps",
    "topNavigationBarProps",
    "broadcastFeedProps",
    "careerProfileFrameProps",
  ],

  data() {
    return {
      surveys: [],
      tabs: [
        { name: "All Forms", active: true },
        { name: "Skill Forms", active: false },
        { name: "Personality Forms", active: false },
      ],
    };
  },
  mounted() {
    this.fetchSurveys();
  },
  methods: {
    activateTab(index) {
      this.tabs.forEach((tab, tabIndex) => {
        tab.active = index === tabIndex;
      });
    },
    async fetchSurveys() {
      console.log("fetc survyes");
      const serverUrl =
        process.env.VUE_APP_SERVER_URL || "http://localhost:8080"; // Fallback to localhost in case the env var isn't set
      const response = await axios.get(`${serverUrl}/v1/surveys`);
      this.surveys = await response.data.data.items;
      console.log(this.surveys);
    },
  },
};
</script>

<style scoped>
.home-feed-student-default {
  align-items: flex-start;
  background-color: #f3f7f8;
  border: 1px none;
  display: flex;
  gap: 25px;
  height: 100vh;
  overflow: hidden;
  padding: 0 18px;
  position: relative;
  max-width: 1512px;
  width: 100%;
}

@media all and (max-width: 900px) {
  .home-feed-student-default {
    align-items: flex-start;
    background-color: #f3f7f8;
    border: 1px none;
    display: flex;
    gap: 25px;
    height: 982px;
    overflow: hidden;
    padding: 0 18px;
    position: relative;
    max-width: 100vw !important;
  }
}

.flex-col {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 30px;
  min-height: 1579px;
  position: relative;
  width: 100%;
}

.frame-container {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 30px;
  min-height: 927px;
  position: relative;
  right: 0%;
  max-width: 291px;
}

@media all and (max-width: 1400px) {
  .frame-container {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 30px;
    min-height: 927px;
    position: absolute;
    right: 0%;
    max-width: 291px;
  }
}

@media all and (max-width: 600px) {
  .frame-container {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 120px;
    min-height: 927px;
    position: absolute;
    right: 0%;
    max-width: 291px;
  }
}
.frame-2354 {
  align-items: center;
  display: flex;
  margin-right: 12px;
  min-width: 109px;
}

.bell-1 {
  height: 20px;
  width: 20px;
}

.cog-1 {
  height: 20px;
  margin-left: 10px;
  width: 20px;
}

.overlap-group {
  align-items: flex-start;

  border: 1px solid;
  border-color: var(--celeste);
  border-radius: 23px;
  display: flex;
  height: 46px;
  margin-left: 13px;
  min-width: 46px;
  padding: 10px 11px;
}

.jj {
  color: var(--x475f4a);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-m);
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  min-height: 24px;
  white-space: nowrap;
}

.broadcast-feed {
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  height: 1479px;
  width: 100%;
}

.overlap-HomeFeedStudentDefaultgroup1 {
  border-radius: 30px;
  height: 951px;
  position: relative;
  width: 100%;
}

@media all and (max-width: 600px) {
  .overlap-HomeFeedStudentDefaultgroup1 {
    border-radius: 30px;
    min-height: 951px;
    position: relative;
    width: 865px;
    margin-top: 80px;
  }
}

.frame-2342 {
  align-items: flex-start;
  background-color: transparent;
  border-radius: 30px;
  display: inline-flex;
  gap: 10px;
  width: 100%;
  left: 0;
  overflow: hidden;
  overflow-y: scroll;
  padding: 40px 28px;
  position: absolute;
  top: 0;
}

.posts-feeed::-webkit-scrollbar {
  display: none;
}

.chat {
  width: 100%;
  min-height: 700px;
  display: flex;
  align-items: flex-start;

  gap: 20px;
  justify-content: space-between;
}
.chat_users {
  width: 30%;
  background: white;
  height: 100%;
  min-height: 700px;
  max-height: 700px;
  border-radius: 30px;
  padding: 40px 20px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
}

.chat_main {
  width: 100%;
}

.chat_user_heading {
  height: 50px;
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.chat_user_heading h1 {
  color: #000;
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  gap: 8px;
  line-height: normal;
}
.message_headings_icons {
  display: flex;
  align-items: center;
  gap: 4px;
}

.message_headings_icons_search_bar {
  height: 30px;
  width: 30px;
  background: #259b3521;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #259b35;
}
.message_headings_icons_search_bar:nth-child(2) {
  height: 30px;
  width: 30px;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: white;
}

.user_heading {
  display: flex;
  align-items: flex-start;
  gap: 4px;
  margin-top: 30px;
  gap: 8px;
  padding-bottom: 10px;
  border-bottom: 1px solid #259b3521;
}
.user_heading img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}
.user_heading h1 {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.user_heading p {
  color: #000;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.scrollable {
  margin-top: 30px;
  max-height: 570px;
  overflow-y: scroll;
}

.chat_body {
  width: 70%;
  min-height: 700px;
  border-radius: 30px;
  background: white;
  height: 100%;
  padding: 40px 20px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
}

.chat_body_user_heading {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  margin-top: 30px;
  gap: 8px;
  padding-bottom: 10px;
}
.chat_body_user_heading img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}
.chat_body_user_heading h1 {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.chat_body_user_heading p {
  color: #ccc;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.chat_body_user_heading p {
  color: #000;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.chat_body_main {
  max-height: 470px;
  min-height: 470px;
  border-radius: 40px;
  background: #f3f7f8;
  width: 100%;
  margin-top: 10px;
  overflow-y: scroll;
}
.chat_form {
  width: 100%;
  height: 58px;
  background-color: white;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 100px;
  border-radius: 10px;
}
.chat_form input {
  width: 90%;
  height: 100%;
  border-radius: 100px;
  padding: 0px 20px;
  border: 1px solid transparent;
  background-color: transparent;
}

.chat_form .send_message {
  height: 100%;
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat_form .send_message button {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  flex-shrink: 0;
  justify-content: center;

  align-items: center;
  border: 1px solid transparent;
  border-radius: 100px;
  background: rgba(37, 155, 53, 0.13);
}

.single_message {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 40px;
  margin-top: 15px;
}

.single_message img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.single_message .message_div {
  padding: 0px 20px;
  height: 100%;
  display: flex;
  align-items: center;
  display: inline-flex;
  color: #475f4a;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: red;
  border-radius: 100px;
  border-radius: 100px;
  background: rgba(37, 155, 53, 0.13);
}
.chat_body_main {
  padding: 20px 20px;
}

.single_message_right {
  display: flex;
  margin-top: 15px;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  height: 40px;
}

.single_message_right img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.single_message_right .message_div {
  padding: 0px 20px;
  height: 100%;
  display: flex;
  align-items: center;
  display: inline-flex;
  color: #475f4a;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: red;
  border-radius: 100px;
  background: #e4e4e4;
}



.cards
{
  width: 100%;
  margin-top: 40px;
  max-height: 600px;
  overflow-y: scroll;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 30px;
}

.single_card_body
{
  display: flex;
width: 100%;
padding: 30px;
flex-direction: column;
align-items: flex-start;
border-radius: 10px;
background: #FFF;
box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
gap: 10px;
}
.card_headings
{
  display: flex;
  align-items: center;
  gap: 10px;
}
.card_headings img 
{
  height: 40px;
  width: 40px;
  border-radius: 20px;

}

.card_headings h1 
{
  color: #000;
font-family: Inter;
font-size: 15px;
font-style: normal;
font-weight: 600;
line-height: normal;
} 

.card_description p 
{
  color: #000;
font-family: Inter;
font-size: 15px;
margin-top: 10px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
</style>
