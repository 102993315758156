<template>
  <div class="container-center-horizontal">
    <form
      class="home-feed-student-default screen"
      name="form3"
      action="form3"
      method="post"
    >
      <left-navigation-bar
        v-bind="leftNavigationBarProps"
        :userObject="userObject"
      />
      <div class="flex-col">
        <top-navigation-bar :place="topNavigationBarProps.place" />
        <div style="display: flex; align-items: flex-start; gap: 20px">
          <broadcast-feed
            :broadcastPost1Props="broadcastFeedProps.broadcastPost1Props"
            :broadcastPost2Props="broadcastFeedProps.broadcastPost2Props"
            :broadcastPost3Props="broadcastFeedProps.broadcastPost3Props"
            :HomeFeedStudentDefaultFrame2337Props="
              broadcastFeedProps.HomeFeedStudentDefaultFrame2337Props
            "
            :HomeFeedStudentDefaultFrame233721Props="
              broadcastFeedProps.HomeFeedStudentDefaultFrame233721Props
            "
            :HomeFeedStudentDefaultFrame233722Props="
              broadcastFeedProps.HomeFeedStudentDefaultFrame233722Props
            "
            :HomeFeedStudentDefaultFrame233723Props="
              broadcastFeedProps.HomeFeedStudentDefaultFrame233723Props
            "
          />

          <div class="frame-container">
            <div class="frame-2354">
              <img
                class="bell-1"
                src="https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/bell-1-3.svg"
                alt="bell 1"
              />
              <img
                class="cog-1"
                src="https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/cog-1-3.svg"
                alt="cog 1"
              />
              <div class="overlap-group" @click="setLogoutDrop">
                <div class="jj">{{ userName }}</div>

                <div
                  :class="
                    logoutDropdown ? 'logoutDropdownShow' : ' absolute_group'
                  "
                >
                  <a href="#">Logout</a>
                </div>
              </div>
            </div>
            <career-profile-frame v-bind="careerProfileFrameProps" />
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import LeftNavigationBar from "./LeftNavigationBar";
import TopNavigationBar from "./TopNavigationBar";
import BroadcastFeed from "./BroadcastFeed";
import CareerProfileFrame from "./CareerProfileFrame";
export default {
  data() {
    return {
      //        userObject: null,
      userName: "Student",
      logoutDropdown: false,
    };
  },
  computed: {
    userObject() {
      if (this.$route.query.userObject) {
        return JSON.parse(this.$route.query.userObject);
      }
      return null;
    },
  },
  methods: {
    setLogoutDrop() {
      this.logoutDropdown = !this.logoutDropdown;
    },
  },
  mounted() {
    console.log("HomeFeedDefault: mounted()...");
    console.log("HomeFeedDefault: mounted()->" + this.userObject);
    console.log(
      "leftNavigationBarProps in HomeFeedStudentDefault:",
      this.leftNavigationBarProps
    );
    if (this.$route.query.userObject) {
      const user = JSON.parse(this.$route.query.userObject);
      this.userObject = user;
console.log("this.userObject inside mounted:" + JSON.stringify(this.userObject));
      if (user && user.name) {
        this.userName = user.name;
        console.log("user name:" + this.userName);
      }
    } else {
      console.log("User object missing");
    }
  },

  name: "HomeFeedStudentDefault",
  components: {
    LeftNavigationBar,
    TopNavigationBar,
    BroadcastFeed,
    CareerProfileFrame,
  },
  props: [
    "jj",
    "leftNavigationBarProps",
    "topNavigationBarProps",
    "broadcastFeedProps",
    "careerProfileFrameProps",
    "userObject",
  ],
};
</script>

<style scoped>
.home-feed-student-default {
  align-items: flex-start;
  background-color: #f3f7f8;
  border: 1px none;
  display: flex;
  gap: 25px;
  height: 982px;
  overflow: hidden;
  padding: 0 18px;
  position: relative;
  max-width: 1512px;
}

.absolute_group {
  position: absolute;
  right: 0%;
  top: 110%;
  width: 100%;
  border-radius: 4px;
  height: 30px;
  width: 200px;
  z-index: 9999;

  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
}

.logoutDropdownShow {
  position: absolute;
  right: 0%;
  top: 110%;
  width: 100%;
  border-radius: 4px;
  height: 30px;
  width: 200px;
  z-index: 9999;

  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
}


.absolute_group a {
  text-decoration: none;
  color: black;
  text-decoration: underline;
}

@media all and (max-width: 900px) {
  .home-feed-student-default {
    align-items: flex-start;
    background-color: #f3f7f8;
    border: 1px none;
    display: flex;
    gap: 25px;
    height: 982px;
    overflow: hidden;
    padding: 0 18px;
    position: relative;
    max-width: 100vw !important;
  }
}

.flex-col {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 30px;
  min-height: 1579px;
  position: relative;
  max-width: 100%;
}

.frame-container {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: -90px;
  min-height: 927px;
  position: relative;
  right: 0%;
  max-width: 291px;
}

@media all and (max-width: 1400px) {
  .frame-container {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 30px;
    min-height: 927px;
    position: absolute;
    right: 0%;
    max-width: 291px;
  }
}

@media all and (max-width: 600px) {
  .frame-container {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 120px;
    min-height: 927px;
    position: absolute;
    right: 0%;
    max-width: 291px;
  }
}
.frame-2354 {
  align-items: center;
  display: flex;
  margin-right: 12px;
  min-width: 109px;
}

.bell-1 {
  height: 20px;
  width: 20px;
}

.cog-1 {
  height: 20px;
  margin-left: 10px;
  width: 20px;
}

.overlap-group {
  align-items: flex-start;
  background-color: var(--white);
  border: 1px solid;
  border-color: var(--celeste);
  border-radius: 23px;
  display: flex;
  height: 46px;
  margin-left: 13px;
  min-width: 46px;
  padding: 10px 11px;
  position: relative;
  cursor: pointer;
}

.jj {
  color: var(--x475f4a);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-m);
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  min-height: 24px;
  white-space: nowrap;
}
</style>
