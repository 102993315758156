 let _isLoggedIn = false;

 export default {
   get isLoggedIn() {
       return _isLoggedIn;
    },
   login() {
       _isLoggedIn = true;
   },
   logout() {
       _isLoggedIn = false;
   }
};
