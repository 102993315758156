<template>
  <div class="broadcast-feed">
    <div class="overlap-HomeFeedStudentDefaultgroup1">
      <div class="frame-2342">
        <div class="posts-feeed">
          <BroadcastPost1
            v-for="post in posts"
            :key="post.id"
            :logo="post.external_link ? post.external_link : ellipse1"
            :surname="post.surname"
            :posted10MinAgo="post.posted10MinAgo"
            :message="post.content"
          />
        </div>
              </div>
      <div class="tags"  >
      <HomeFeedStudentDefaultFrame2337
        :all="HomeFeedStudentDefaultFrame2337Props.all"
      />
      <br />
      <HomeFeedStudentDefaultFrame23372
        :all="HomeFeedStudentDefaultFrame233721Props.all"
      />
      <HomeFeedStudentDefaultFrame23372
        :all="HomeFeedStudentDefaultFrame233722Props.all"
        :className="HomeFeedStudentDefaultFrame233722Props.className"
      />
      <HomeFeedStudentDefaultFrame23372
        :all="HomeFeedStudentDefaultFrame233723Props.all"
        :className="HomeFeedStudentDefaultFrame233723Props.className"
      />
      </div>
    </div>
  </div>
</template>

<script>
import BroadcastPost1 from "./BroadcastPost1";
import BroadcastPost2 from "./BroadcastPost2";
import BroadcastPost3 from "./BroadcastPost3";
import HomeFeedStudentDefaultFrame2337 from "./HomeFeedStudentDefaultFrame2337";
import HomeFeedStudentDefaultFrame23372 from "./HomeFeedStudentDefaultFrame23372";

import axios from "axios";
export default {
  data() {
    return {
      posts: [],
      error: null,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        // Use the environment variable here
        const serverUrl =
          process.env.VUE_APP_SERVER_URL || "http://localhost:8080"; // Fallback to localhost in case the env var isn't set
        const response = await axios.get(`${serverUrl}/v1/broadcast/?userid=3`);
        this.posts = response.data.data;
      } catch (err) {
        this.error = err.message || "An error occurred";
        console.log(this.error);
      }
    },
  },
  name: "BroadcastFeed",
  components: {
    BroadcastPost1,
    BroadcastPost2,
    BroadcastPost3,
    HomeFeedStudentDefaultFrame2337,
    HomeFeedStudentDefaultFrame23372,
  },
  props: [
    "broadcastPost1Props",
    "broadcastPost2Props",
    "broadcastPost3Props",
    "HomeFeedStudentDefaultFrame2337Props",
    "HomeFeedStudentDefaultFrame233721Props",
    "HomeFeedStudentDefaultFrame233722Props",
    "HomeFeedStudentDefaultFrame233723Props",
  ],
};
</script>

<style scoped>
.broadcast-feed {
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  height: 1479px;
  width: 100%;
  
}

.overlap-HomeFeedStudentDefaultgroup1 {
  border-radius: 30px;
  height: 951px;
  position: relative;
  width: 865px;
}


@media all and (max-width: 600px){
  .overlap-HomeFeedStudentDefaultgroup1 {
  border-radius: 30px;
  min-height: 951px;
  position: relative;
  width: 865px;
  margin-top: 80px;
}
}

.frame-2342 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 30px;
  box-shadow: 0px 0px 15px #0000000d;
  display: inline-flex;
  gap: 10px;
  width: 100%;
  left: 0;
  overflow: hidden;
  padding: 90px 28px;
  position: absolute;
  top: 0;
}

 

.posts-feeed::-webkit-scrollbar {
  display: none;
  width: 0;
}

.posts-feeed {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 30px;
  height: 771px;
  overflow: hidden;
  overflow-y: scroll;
  position: relative;
  width: 100%;
}

.feed_card {
  border-radius: 20px;
  border: 0.5px solid #ccc;
  background: #fff;
  padding: 30px 20px;
  width: 100%;
}

.headings_card {
  display: flex;
  align-items: center;
  gap: 8px;
}

.heading_image img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.heading_texts h1 {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.heading_texts p {
  color: #ccc;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  margin-top: 2px;
  line-height: normal;
}

.paragraph_card {
  margin-top: 20px;
  margin-bottom: 30px;
}
.paragraph_card p {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.card_buttons {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.card_buttons .link {
  padding: 10px 20px !important;
  border-radius: 100px;
  background: #259b35 !important;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  display: block;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-decoration: none;
  margin-left: auto;
}

.card_social {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 20px;
  justify-content: flex-end;
}

@media all and (max-width: 800px){
  .tags
{
  z-index: 9999; 
  height: 100px;
  display:flex;flex-wrap:wrap;position:relative
}
}
</style>
