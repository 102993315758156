<template>
  <div class="login-box">
    <div class="email-address-box">
      <div class="email-address inter-medium-gray-asparagus-15px">{{ emailAddress }}</div>
      <div class="overlap-LoginBox_gr-3">
        <img
          class="envelope-square-1"
          src="https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/envelope-square-1.svg"
          alt="envelope-square 1"
        />
        <input
          class="emailexamplecom inter-medium-celeste-15px"
          name="emailexamplecom"
          :placeholder="inputPlaceholder1"
          :type="inputType1"
          required
          v-model="emailValue"
        />
      </div>
    </div>
    <div class="password-box">
      <div class="passwords inter-medium-gray-asparagus-15px">{{ passwords }}</div>
      <div class="overlap-LoginBox_gr1-2">
        <img
          class="lock-alt-1"
          src="https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64bf07089c71654b57c5b973/img/lock-alt-1.svg"
          alt="lock-alt 1"
        />
        <input
          class="password inter-medium-celeste-15px"
          name="password"
          :placeholder="inputPlaceholder2"
          :type="inputType2"
          required
          v-model="password"
        />
      </div>
    </div>
    <div class="forgot-password">{{ forgotPassword }}</div>
    <a href="javascript:SubmitForm('form1')" @click="loginMYSQL">
      <div class="login-button">
        <div class="overlap-LoginBox_gr2-1">
          <div class="login">{{ login }}</div>
        </div>
      </div></a
    >
  </div>
</template>

<script>
import Vue from "vue";
import Router from "vue-router";
import axios from 'axios';
import authService from './authService';

export default {
  name: "LoginBox",
  props: [
    "emailAddress",
    "inputType1",
    "inputPlaceholder1",
    "passwords",
    "inputType2",
    "inputPlaceholder2",
    "forgotPassword",
    "login",
  ],
  data() {
    return {
        emailValue: '',
        password:'',
        daResponse: [],
    };
  },
 methods: {
    da_login () {
         console.log("LoginBox:login");
         authService.login();  // Set user as logged in
         this.$router.push({
                path: '/homeFeed',
                query: {userObject: JSON.stringify(this.daResponse.data[0]) }
          });
    },
    async loginMYSQL() {

    console.log("login called");
    if (this.emailValue != "cofebe@cofebe.com") {
       try {
        const payLoad = {
            email: this.emailValue,
            password: this.password
        };
        // Use the environment variable here
        const serverUrl = process.env.VUE_APP_SERVER_URL || 'http://localhost:8080'; // Fallback to localhost in case the env var isn't set
        const response = await axios.post(`${serverUrl}/v1/login_mysql`, payLoad);

        this.daResponse = response.data

       console.log("loginMYSQL:daResponse" + JSON.stringify(this.daResponse));
        // Send user to the home page along with the user data
        if (this.daResponse && this.daResponse.data && this.daResponse.data[0]) {
            this.$router.push({
                path: '/homeFeed',
                query: {userObject: JSON.stringify(this.daResponse.data[0]) }
            });
        } else {
         console.log("loginMYSQL - bad response");
        }

     } catch (err) {
        this.error = err.message || 'An error occurred';
        console.log("Error" + err);
    }
    } else {
        console.log("by passingl login...");
        this.$router.push('/homeFeed');
    }
}


    },
};
</script>

<style>
.login-box {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  margin-top: 31px;
  min-height: 253px;
  width: 384px;
}

.email-address-box {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-right: -4px;
  min-height: 63px;
  width: 388px;
}

.email-address,
.passwords {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 10px;
  min-height: 18px;
  white-space: nowrap;
}

.overlap-LoginBox_gr-3 {
  align-items: center;
  background-color: var(--black-haze);
  border-radius: 5px;
  display: flex;
  gap: 28px;
  height: 40px;
  min-width: 384px;
  padding: 0 10px;
}

.envelope-square-1 {
  height: 26px;
  width: 23px;
}

.emailexamplecom {
  background-color: transparent;
  border: 0;
  height: 18px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: 6px;
  padding: 0;
  white-space: nowrap;
  width: 149px;
}

.emailexamplecom::placeholder,
.password::placeholder {
  color: #cccccc99;
}

.password-box {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-right: -4px;
  margin-top: 30px;
  min-height: 63px;
  width: 388px;
}

.overlap-LoginBox_gr1-2 {
  align-items: center;
  background-color: var(--black-haze);
  border-radius: 5px;
  display: flex;
  gap: 29px;
  height: 40px;
  min-width: 384px;
  padding: 0 10px;
}

.lock-alt-1 {
  height: 26px;
  width: 22px;
}

.password {
  background-color: transparent;
  border: 0;
  height: 18px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: 6px;
  padding: 0;
  white-space: nowrap;
  width: 311px;
}

.forgot-password {
  color: var(--style);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-s);
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: 9px;
  min-height: 15px;
  min-width: 96px;
}

.login-button {
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  margin-right: -2px;
  margin-top: 21px;
  min-width: 386px;
  opacity: 0.5;
}

.overlap-LoginBox_gr2-1 {
  align-items: center;
  background-color: var(--style);
  border-radius: 5px;
  display: flex;
  height: 52px;
  min-width: 384px;
  padding: 0 166px;
  width: 100%;
}

.login {
  color: var(--white);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-l);
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  min-height: 24px;
  min-width: 52px;
  white-space: nowrap;
}
</style>
