<template>
  <div class="survey-card">
    <div class="overlap-StudentLoginPage_gr-2">
      <div class="StudentLoginPage_gr-2351-1">
        <StudentLoginPage_gr2349 :src="StudentLoginPage_gr2349Props.src" />
        <div class="StudentLoginPage_gr-2350-1">
          <div class="about-me inter-medium-gray-asparagus-15px">{{ aboutMe }}</div>
          <div class="address inter-normal-bud-10px">{{ address }}</div>
        </div>
      </div>
      <p class="lorem-ipsum-dolor-si-1 inter-light-black-15px">{{ loremIpsumDolorSi }}</p>
      <div class="percent inter-normal-bud-10px">{{ percent }}</div>
      <div class="overlap-StudentLoginPage_gr1-1">
        <div class="rectangle-43"></div>
      </div>
    </div>
  </div>
</template>

<script>
import StudentLoginPage_gr2349 from "./StudentLoginPage_gr2349";
export default {
  name: "StudentLoginPage_fr23472",
  components: {
    StudentLoginPage_gr2349,
  },
  props: ["aboutMe", "address", "loremIpsumDolorSi", "percent", "StudentLoginPage_gr2349Props"],
};
</script>

<style>
.survey-card {
  align-items: flex-start;
  display: flex;
  height: 163px;
  left: 0;
  min-width: 180px;
  position: absolute;
  top: 245px;
}

.overlap-StudentLoginPage_gr-2 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 15px #0000000d;
  display: flex;
  flex-direction: column;
  min-height: 163px;
  padding: 10px;
  width: 180px;
}

.StudentLoginPage_gr-2351-1 {
  align-items: flex-start;
  display: flex;
  gap: 10px;
  height: 35px;
  min-width: 108px;
  position: relative;
}

.StudentLoginPage_gr-2350-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px;
  min-height: 35px;
  width: 74px;
}

.about-me {
  letter-spacing: 0;
  line-height: normal;
  min-height: 18px;
  white-space: nowrap;
}

.address {
  letter-spacing: 0;
  line-height: normal;
  min-height: 12px;
  white-space: nowrap;
}

.lorem-ipsum-dolor-si-1 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: 10px;
  min-height: 54px !important;
  width: 156px !important;
}

.percent {
  align-self: flex-end;
  letter-spacing: 0;
  line-height: normal;
  margin-right: 4px;
  margin-top: 4px;
  min-height: 12px;
  min-width: 21px;
  white-space: nowrap;
}

.overlap-StudentLoginPage_gr1-1 {
  align-items: center;
  background-color: var(--black-haze);
  border-radius: 100px;
  display: flex;
  height: 13px;
  margin-top: 4px;
  min-width: 156px;
}

.rectangle-43 {
  background-color: var(--style);
  border-radius: 100px;
  height: 11px;
  width: 73px;
}
</style>
