<template>
  <div class="StudentLoginPage_gr">
    <div class="flex-col-6">
      <div class="central-methodist-university inter-light-black-15px">Central Methodist University</div>
      <div class="st-peters-missouri-1 inter-light-black-10px">St. Peters Missouri</div>
    </div>
    <img class="rectangle-10-2" :src="rectangle10" alt="Rectangle 10" />
  </div>
</template>

<script>
export default {
  name: "StudentLoginPage_gr9",
  props: ["rectangle10"],
};
</script>

<style>
.StudentLoginPage_gr {
  height: 33px;
  position: relative;
  width: 250px;
}

.flex-col-6 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 6px;
  left: 40px;
  min-height: 33px;
  position: absolute;
  top: 0;
  width: 206px;
}

.central-methodist-university {
  letter-spacing: 0;
  line-height: normal;
  min-height: 16px;
  white-space: nowrap;
}

.st-peters-missouri-1 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 11px;
  white-space: nowrap;
}

.rectangle-10-2 {
  height: 27px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 30px;
}
</style>
