<template>
  <div class="container-center-horizontal">
    <form
      class="home-feed-student-default screen"
      name="form3"
      action="form3"
      method="post"
    >
      <left-navigation-bar v-bind="leftNavigationBarProps" />
      <div class="flex-col">
        <top-navigation-bar />
        <div class="broadcast-feed">
          <div class="overlap-HomeFeedStudentDefaultgroup1">
            <div
              class="frame-2342"
              style="display: flex; align-items: start; flex-direction: column"
            >
              <div class="grid_div">
                <div class="first_div">
                  <div>
                    <h1>Joe Johnson</h1>
                  </div>
                  <div class="personal_info">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                      >
                        <path
                          d="M29.4316 11.1797C29.6602 10.998 30 11.168 30 11.4551V23.4375C30 24.9902 28.7402 26.25 27.1875 26.25H2.8125C1.25977 26.25 0 24.9902 0 23.4375V11.4609C0 11.168 0.333984 11.0039 0.568359 11.1855C1.88086 12.2051 3.62109 13.5 9.59766 17.8418C10.834 18.7441 12.9199 20.6426 15 20.6309C17.0918 20.6484 19.2188 18.709 20.4082 17.8418C26.3848 13.5 28.1191 12.1992 29.4316 11.1797ZM15 18.75C16.3594 18.7734 18.3164 17.0391 19.3008 16.3242C27.0762 10.6816 27.668 10.1895 29.4609 8.7832C29.8008 8.51953 30 8.10938 30 7.67578V6.5625C30 5.00977 28.7402 3.75 27.1875 3.75H2.8125C1.25977 3.75 0 5.00977 0 6.5625V7.67578C0 8.10938 0.199219 8.51367 0.539062 8.7832C2.33203 10.1836 2.92383 10.6816 10.6992 16.3242C11.6836 17.0391 13.6406 18.7734 15 18.75Z"
                          fill="#259B35"
                        />
                      </svg>
                      <p>JJohnson@example.com</p>
                    </div>
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="38"
                        height="30"
                        viewBox="0 0 38 30"
                        fill="none"
                      >
                        <path
                          d="M36.347 9.02288L20.6126 4.00256C19.5552 3.66565 18.4454 3.66565 17.388 4.00256L1.65359 9.02288C0.649563 9.34338 0 10.2176 0 11.25C0 12.2824 0.649563 13.1567 1.653 13.4772L3.41703 14.0397C3.22109 14.4018 3.07622 14.7914 2.98181 15.1998C2.34709 15.5033 1.9 16.1321 1.9 16.875C1.9 17.6209 2.34947 18.2531 2.98834 18.5549L1.91662 25.1092C1.82103 25.6946 2.14878 26.25 2.58994 26.25H5.00947C5.45062 26.25 5.77897 25.6946 5.68278 25.1092L4.61166 18.5549C5.25053 18.2531 5.7 17.6209 5.7 16.875C5.7 16.2539 5.37403 15.7301 4.90259 15.3885C4.99106 15.1155 5.12822 14.8694 5.29269 14.6379L8.46747 15.651L7.6 22.5C7.6 24.5713 12.7039 26.25 19 26.25C25.2961 26.25 30.4 24.5713 30.4 22.5L29.5325 15.651L36.347 13.4766C37.3504 13.1567 38 12.2824 38 11.25C38 10.2176 37.3504 9.34338 36.347 9.02288ZM28.4691 22.3746C27.8302 23.0385 24.5836 24.375 19 24.375C13.4164 24.375 10.1698 23.0385 9.53088 22.3746L10.3081 16.2381L17.388 18.4969C17.5423 18.5461 18.9151 19.0377 20.6126 18.4969L27.6925 16.2381L28.4691 22.3746ZM35.7604 11.6942L20.026 16.7145C19.3545 16.9289 18.6455 16.9289 17.974 16.7145L7.54359 13.3864L19.1746 11.2336C19.6905 11.1387 20.0302 10.6489 19.9334 10.1397C19.8372 9.62991 19.3301 9.29651 18.8254 9.39143L6.726 11.6297C5.98975 11.7656 5.33009 12.078 4.76187 12.4987L2.23903 11.6936C1.77294 11.5442 1.79609 10.9471 2.23903 10.8059L17.9734 5.78557C18.87 5.49963 19.6264 5.65842 20.0254 5.78557L35.7598 10.8059C36.1986 10.9459 36.2294 11.5442 35.7604 11.6942Z"
                          fill="#259B35"
                        />
                      </svg>
                      <p>North Ridge High School</p>
                    </div>
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="28"
                        height="28"
                        viewBox="0 0 28 28"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_17_725)">
                          <path
                            d="M0 11.6667V28H28V11.6667H0ZM0 9.33333V5.83333C1.90735e-06 4.90508 0.36875 4.01484 1.02513 3.35846C1.6815 2.70208 2.57174 2.33333 3.5 2.33333H7V0H9.33333V2.33333H18.6667V0H21V2.33333H24.5C25.4283 2.33333 26.3185 2.70208 26.9749 3.35846C27.6313 4.01484 28 4.90508 28 5.83333V9.33333H0ZM7 16.3333H9.33333V18.6667H7V16.3333ZM12.8333 16.3333H15.1667V18.6667H12.8333V16.3333ZM18.6667 16.3333H21V18.6667H18.6667V16.3333ZM7 21H9.33333V23.3333H7V21ZM12.8333 21H15.1667V23.3333H12.8333V21ZM18.6667 21H21V23.3333H18.6667V21Z"
                            fill="#259B35"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_17_725">
                            <rect
                              width="28"
                              height="28"
                              fill="white"
                              transform="matrix(-1 0 0 1 28 0)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      <p>June 2024</p>
                    </div>
                  </div>

                  <div class="personal_info">
                    <div class="flex_title">
                      <span class="title_t">My Skills</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        viewBox="0 0 25 25"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_17_711)">
                          <path
                            d="M12.5 0C10.0277 0 7.61099 0.733112 5.55538 2.10663C3.49976 3.48015 1.89761 5.43238 0.951511 7.71646C0.00541608 10.0005 -0.242126 12.5139 0.24019 14.9386C0.722505 17.3634 1.91301 19.5907 3.66117 21.3388C5.40933 23.087 7.63661 24.2775 10.0614 24.7598C12.4861 25.2421 14.9995 24.9946 17.2835 24.0485C19.5676 23.1024 21.5199 21.5002 22.8934 19.4446C24.2669 17.389 25 14.9723 25 12.5C24.9964 9.18589 23.6783 6.00855 21.3349 3.66512C18.9915 1.3217 15.8141 0.00358446 12.5 0ZM16.6667 13.5417H13.5417V16.6667C13.5417 16.9429 13.4319 17.2079 13.2366 17.4032C13.0412 17.5986 12.7763 17.7083 12.5 17.7083C12.2237 17.7083 11.9588 17.5986 11.7634 17.4032C11.5681 17.2079 11.4583 16.9429 11.4583 16.6667V13.5417H8.33334C8.05707 13.5417 7.79212 13.4319 7.59677 13.2366C7.40142 13.0412 7.29167 12.7763 7.29167 12.5C7.29167 12.2237 7.40142 11.9588 7.59677 11.7634C7.79212 11.5681 8.05707 11.4583 8.33334 11.4583H11.4583V8.33333C11.4583 8.05707 11.5681 7.79211 11.7634 7.59676C11.9588 7.40141 12.2237 7.29167 12.5 7.29167C12.7763 7.29167 13.0412 7.40141 13.2366 7.59676C13.4319 7.79211 13.5417 8.05707 13.5417 8.33333V11.4583H16.6667C16.9429 11.4583 17.2079 11.5681 17.4032 11.7634C17.5986 11.9588 17.7083 12.2237 17.7083 12.5C17.7083 12.7763 17.5986 13.0412 17.4032 13.2366C17.2079 13.4319 16.9429 13.5417 16.6667 13.5417Z"
                            fill="#259B35"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_17_711">
                            <rect width="25" height="25" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div>
                      <p>Project Management</p>
                    </div>
                    <div>
                      <p>Leadership</p>
                    </div>
                  </div>

                  <div class="personal_info">
                    <div class="flex_title">
                      <p class="title_t">My Hobbies/ Interests</p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        viewBox="0 0 25 25"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_17_711)">
                          <path
                            d="M12.5 0C10.0277 0 7.61099 0.733112 5.55538 2.10663C3.49976 3.48015 1.89761 5.43238 0.951511 7.71646C0.00541608 10.0005 -0.242126 12.5139 0.24019 14.9386C0.722505 17.3634 1.91301 19.5907 3.66117 21.3388C5.40933 23.087 7.63661 24.2775 10.0614 24.7598C12.4861 25.2421 14.9995 24.9946 17.2835 24.0485C19.5676 23.1024 21.5199 21.5002 22.8934 19.4446C24.2669 17.389 25 14.9723 25 12.5C24.9964 9.18589 23.6783 6.00855 21.3349 3.66512C18.9915 1.3217 15.8141 0.00358446 12.5 0ZM16.6667 13.5417H13.5417V16.6667C13.5417 16.9429 13.4319 17.2079 13.2366 17.4032C13.0412 17.5986 12.7763 17.7083 12.5 17.7083C12.2237 17.7083 11.9588 17.5986 11.7634 17.4032C11.5681 17.2079 11.4583 16.9429 11.4583 16.6667V13.5417H8.33334C8.05707 13.5417 7.79212 13.4319 7.59677 13.2366C7.40142 13.0412 7.29167 12.7763 7.29167 12.5C7.29167 12.2237 7.40142 11.9588 7.59677 11.7634C7.79212 11.5681 8.05707 11.4583 8.33334 11.4583H11.4583V8.33333C11.4583 8.05707 11.5681 7.79211 11.7634 7.59676C11.9588 7.40141 12.2237 7.29167 12.5 7.29167C12.7763 7.29167 13.0412 7.40141 13.2366 7.59676C13.4319 7.79211 13.5417 8.05707 13.5417 8.33333V11.4583H16.6667C16.9429 11.4583 17.2079 11.5681 17.4032 11.7634C17.5986 11.9588 17.7083 12.2237 17.7083 12.5C17.7083 12.7763 17.5986 13.0412 17.4032 13.2366C17.2079 13.4319 16.9429 13.5417 16.6667 13.5417Z"
                            fill="#259B35"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_17_711">
                            <rect width="25" height="25" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div>
                      <p>Entrepreneurship</p>
                    </div>
                    <div>
                      <p>Foreign Policy</p>
                    </div>
                  </div>
                </div>

                <div class="second">
                  <p>
                    Seeking a part-time job or summer internship in tech. More
                    about me...Lorem ipsum dolor sit amet, consectetur
                    adipiscing elit, sed do eiusmod tempor incididunt ut labore
                    et dolore magna aliqua. Ut enim ad minim veniam, quis
                    nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                    commodo consequat.
                  </p>

                  <div class="academics">
                    <h1>Academics</h1>
                    <div class="exams">
                      <div class="exams_one">
                        <h1>1240</h1>
                        <p>SAT</p>
                      </div>
                      <div class="exams_one">
                        <h1>98</h1>
                        <p>ASVAB</p>
                      </div>
                      <div class="exams_one">
                        <h1>26</h1>
                        <p>ACT</p>
                      </div>
                      <div class="exams_one">
                        <h1>3.29</h1>
                        <p>GPA</p>
                      </div>
                    </div>
                  </div>

                  <div class="academics">
                    <h1>Clubs & Activities</h1>
                    <div class="abcde">
                      <div class="abcd">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="13"
                          height="12"
                          viewBox="0 0 13 12"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_17_756)">
                            <path
                              d="M10.3662 8.56289C9.8745 7.29281 9.05057 6.8325 8.11973 6.69586L11.9089 2.98219C12.3575 2.5425 12.3573 1.82977 11.9089 1.39008L10.8266 0.329531C10.6023 0.109922 10.3081 0 10.0142 0C9.72023 0 9.42606 0.109922 9.20172 0.329766L5.74528 3.71648L3.78243 0.840234C3.35648 0.244687 2.79515 0 2.23574 0C0.686895 0 -0.847601 1.87547 0.546026 3.37617L3.18715 6.22289L0.456577 8.89828L0.00837824 11.4166C-0.0566752 11.783 0.276724 12.0471 0.59649 11.9923L3.1661 11.5507L5.40733 9.35414C5.55107 10.4067 6.22457 12 8.80135 12C11.2248 12 12.2454 10.0917 12.2454 7.92023C11.982 8.09648 11.0577 8.82609 10.7589 8.82609C10.5815 8.82609 10.4296 8.72672 10.3662 8.56289ZM9.74272 0.860156C9.84054 0.764297 10.1878 0.764297 10.2856 0.860156L11.3679 1.92047C11.5173 2.06695 11.5173 2.30531 11.3679 2.4518L10.267 3.53062L8.64207 1.93828L9.74272 0.860156ZM1.11237 2.87156C0.757449 2.4893 0.67398 2.08219 0.863879 1.66125C1.09826 1.14164 1.68805 0.75 2.23574 0.75C2.59928 0.75 2.90876 0.925078 3.15582 1.27078L5.19352 4.25719L3.7291 5.69203L1.11237 2.87156ZM2.79563 10.853L0.825134 11.1914L1.16906 9.26086L8.10083 2.46867L9.72573 4.06102L2.79563 10.853ZM8.80135 11.25C7.86191 11.25 7.1621 11.007 6.72156 10.5277C6.26212 10.0277 6.0947 9.27422 6.15378 8.62266L6.55725 8.22727L7.51464 7.39313C9.22923 7.50117 9.48657 8.40492 9.65064 8.82891C9.82595 9.28242 10.261 9.57586 10.7587 9.57586C10.9158 9.57586 11.0737 9.53953 11.2543 9.46195C10.9574 10.3718 10.2861 11.25 8.80135 11.25Z"
                              fill="black"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_17_756">
                              <rect width="12.2454" height="12" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        <p>Creative Writing</p>
                      </div>
                      <p class="abcd_para">
                        Writing typically fiction poetry, which displays
                        imagination or invention.
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="btns">
                    <a href="#" class="single_btn">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_189_1097)">
                          <path
                            d="M17.34 7.59003L13.29 11.775C13.14 11.925 12.945 12 12.75 12C12.555 12 12.375 11.9325 12.225 11.79C11.925 11.505 11.9175 11.025 12.21 10.7325L16.065 6.75753H9.75C8.5125 6.75753 7.5 7.77003 7.5 9.00753V12.7575C7.5 13.17 7.1625 13.5075 6.75 13.5075C6.3375 13.5075 6 13.17 6 12.7575V9.00753C6 6.93753 7.68 5.25753 9.75 5.25753H16.065L12.21 1.27503C11.925 0.975026 11.925 0.502526 12.225 0.217526C12.525 -0.0674737 12.9975 -0.0674737 13.2825 0.232526L17.34 4.42503C18.21 5.29503 18.21 6.72003 17.3325 7.59753L17.34 7.59003ZM11.1825 12.8625C10.905 12.5925 9.78 11.1 9 10.05C9 10.05 9 11.55 9 12.66C9 13.77 8.265 14.745 7.2 14.955C5.76 15.2325 4.5 14.1375 4.5 12.75V9.00003C4.5 7.53003 5.1075 6.20253 6.0825 5.25003H3.75C1.68 5.25003 0 6.93003 0 9.00003V14.25C0 16.32 1.68 18 3.75 18H9C11.07 18 12.75 16.32 12.75 14.25V13.5C12.165 13.5 11.6025 13.275 11.1825 12.8625Z"
                            fill="#475F4A"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_189_1097">
                            <rect width="18" height="18" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      Share
                    </a>
                    <a href="#" class="single_btn">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="14"
                        viewBox="0 0 16 14"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_189_1101)">
                          <path
                            d="M10.6667 3.3332C10.6667 3.16094 10.5972 2.99414 10.4722 2.87109L7.75278 0.191406C7.62778 0.0683594 7.45833 0 7.28056 0H7.11111V3.5H10.6667V3.3332ZM15.8611 8.42188L13.2028 5.78594C12.9222 5.50977 12.4417 5.70391 12.4417 6.09492V7.875H10.6639V9.625H12.4417V11.4078C12.4417 11.7988 12.9222 11.993 13.2028 11.7168L15.8611 9.07812C16.0444 8.89766 16.0444 8.60234 15.8611 8.42188ZM5.33333 9.1875V8.3125C5.33333 8.07187 5.53333 7.875 5.77778 7.875H10.6667V4.375H6.88889C6.52222 4.375 6.22222 4.07969 6.22222 3.71875V0H0.666667C0.297222 0 0 0.292578 0 0.65625V13.3438C0 13.7074 0.297222 14 0.666667 14H10C10.3694 14 10.6667 13.7074 10.6667 13.3438V9.625H5.77778C5.53333 9.625 5.33333 9.42813 5.33333 9.1875Z"
                            fill="#475F4A"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_189_1101">
                            <rect width="16" height="14" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      Export
                    </a>
                  </div>
                  <Accordion />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import LeftNavigationBar from "./LeftNavigationBar";
import TopNavigationBar from "./TopNavigationBar";
import BroadcastFeed from "./BroadcastFeed";
import CareerProfileFrame from "./CareerProfileFrame";
import Accordion from "./Accordion.vue";
import axios from "axios";
export default {
  name: "HomeFeedStudentDefault",
  components: {
    LeftNavigationBar,
    TopNavigationBar,
    BroadcastFeed,
    CareerProfileFrame,
    Accordion,
  },
  props: [
    "jj",
    "leftNavigationBarProps",
    "topNavigationBarProps",
    "broadcastFeedProps",
    "careerProfileFrameProps",
  ],

  data() {
    return {
      surveys: [],
      tabs: [
        { name: "All Forms", active: true },
        { name: "Skill Forms", active: false },
        { name: "Personality Forms", active: false },
      ],
    };
  },
  mounted() {
    this.fetchSurveys();
  },
  methods: {
    activateTab(index) {
      this.tabs.forEach((tab, tabIndex) => {
        tab.active = index === tabIndex;
      });
    },
    async fetchSurveys() {
      console.log("fetc survyes");
      const serverUrl =
        process.env.VUE_APP_SERVER_URL || "http://localhost:8080"; // Fallback to localhost in case the env var isn't set
      const response = await axios.get(`${serverUrl}/v1/surveys`);
      this.surveys = await response.data.data.items;
      console.log(this.surveys);
    },
  },
};
</script>

<style scoped>
.home-feed-student-default {
  align-items: flex-start;
  background-color: #f3f7f8;
  border: 1px none;
  display: flex;
  gap: 25px;
  min-height: 100vh;
  overflow: hidden;
  padding: 0 18px;
  position: relative;
  max-width: 1512px;
  width: 100%;
}

@media all and (max-width: 900px) {
  .home-feed-student-default {
    align-items: flex-start;
    background-color: #f3f7f8;
    border: 1px none;
    display: flex;
    gap: 25px;
    height: 982px;
    overflow: hidden;
    padding: 0 18px;
    position: relative;
    max-width: 100vw !important;
  }
}

.flex-col {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 30px;
  min-height: 1579px;
  position: relative;
  width: 100%;
}

.frame-container {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 30px;
  min-height: 927px;
  position: relative;
  right: 0%;
  max-width: 291px;
}

@media all and (max-width: 1400px) {
  .frame-container {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 30px;
    min-height: 927px;
    position: absolute;
    right: 0%;
    max-width: 291px;
  }
}

@media all and (max-width: 600px) {
  .frame-container {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 120px;
    min-height: 927px;
    position: absolute;
    right: 0%;
    max-width: 291px;
  }
}
.frame-2354 {
  align-items: center;
  display: flex;
  margin-right: 12px;
  min-width: 109px;
}

.bell-1 {
  height: 20px;
  width: 20px;
}

.cog-1 {
  height: 20px;
  margin-left: 10px;
  width: 20px;
}

.overlap-group {
  align-items: flex-start;

  border: 1px solid;
  border-color: var(--celeste);
  border-radius: 23px;
  display: flex;
  height: 46px;
  margin-left: 13px;
  min-width: 46px;
  padding: 10px 11px;
}

.jj {
  color: var(--x475f4a);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-m);
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  min-height: 24px;
  white-space: nowrap;
}

.broadcast-feed {
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  height: 1479px;
  width: 100%;
}

.overlap-HomeFeedStudentDefaultgroup1 {
  border-radius: 30px;
  height: 951px;
  position: relative;
  width: 100%;
}

@media all and (max-width: 600px) {
  .overlap-HomeFeedStudentDefaultgroup1 {
    border-radius: 30px;
    min-height: 951px;
    position: relative;
    width: 865px;
    margin-top: 80px;
  }
}

.frame-2342 {
  align-items: flex-start;
  background-color: transparent;
  border-radius: 30px;
  display: inline-flex;
  gap: 10px;
  width: 100%;
  background-color: white;
  left: 0;
  overflow: hidden;
  height: 100%;
  overflow-y: scroll;
  padding: 40px 28px;
  position: absolute;
  top: 0;
}

.posts-feeed::-webkit-scrollbar {
  display: none;
}

.grid_div {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 40px;
  width: 100%;
}

.first_div div h1 {
  color: #000;
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.personal_info {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 60px;
}

.personal_info div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.personal_info div p {
  color: #000;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.personal_info .title_t {
  color: #a5ac9d;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;

  display: inline-flex;
  font-weight: 500;
  line-height: normal;
}

.flex_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.second p {
  color: #000;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.academics {
  margin-top: 30px;
}
.academics h1 {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.exams {
  display: flex;
  align-items: center;
  margin-top: 20px;
  justify-content: space-between;
}
.exams_one {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;
}

.exams_one h1 {
  color: #475f4a;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.exams_one p {
  color: #636060;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.abcd {
  display: flex;
  align-items: center;
  gap: 8px;
}

.abcd p {
  color: #636060;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.abcd_para {
  color: #000;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.abcde {
  display: flex;
  align-items: flex-start;
  margin-top: 30px;
  flex-direction: column;
  gap: 10px;
}

.second {
  margin-top: 30px;
}

.btns {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 14px;
}
.single_btn {
  color: #475f4a;
  font-family: Inter;
  font-size: 15px;
   
  border-radius: 100px;
  display: inline-flex;
  padding: 5px 20px;
  align-items: flex-start;
  gap: 10px;
  background: #f0f4f0;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media all and  (max-width: 1000px){
  .grid_div {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 40px;
  width: 100%;
}
}

@media all and  (max-width: 600px){
  .grid_div {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 40px;
  width: 100%;
}
}
</style>
