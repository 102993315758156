<template>
  <div class="container-center-horizontal">
    <form
      class="home-feed-student-default screen"
      name="form3"
      action="form3"
      method="post"
    >
      <left-navigation-bar v-bind="leftNavigationBarProps" />
      <div class="flex-col">
        <top-navigation-bar />
        <div class="broadcast-feed">
          <div class="overlap-HomeFeedStudentDefaultgroup1">
            <div
              class="frame-2342"
              style="display: flex; align-items: start; flex-direction: column"
            >
              <div style="width: 100%">
                <div
                  style="
                    display: flex;
                    align-items: start;
                    justify-content: space-between;
                    height: 40px;
                  "
                >
                  <h1 class="top_heading_btn_top_survey">Survey</h1>
                </div>
                <div
                  class="w-full main_percentage h-5 rounded-full overflow-hidden bg-red-500"
                >
                  <div
                    class="w-[65%] main_percentage_one bg-red-900 h-full"
                  ></div>
                </div>
              </div>

              <!---->
          
              <!-- Tabs -->
              <div class="tab_main">
                <div class="tabs">
                  <div
                    v-for="(tab, index) in tabs"
                    :key="index"
                    :class="{ one: tab.active, inactive: !tab.active }"
                    @click="activateTab(index)"
                  >
                    {{ tab.name }}
                  </div>
                </div>
              </div>
              <!--one-->
              <div class="posts-feeed_two">
                <div class="line_one">
                  <div v-for="survey in surveys" :key="survey.id" class="p-4 parent_survey">
                    <div
                      class="recent-survey px-3 py-2 inline-flex rounded-sm"
                    >
                      <div class="recent-survey-main">
                        <div
                          class="recent-survey-heading flex items-start gap-2"
                        >
                          <img
                            class="h-8 w-8 rounded-full"
                            :src = "ellipse"
                            alt=""
                            />
                          <div class="">
                              <h1>{{ survey.type }}</h1>
                            <p class="text-xs time">5 mins ago</p>
                          </div>
                        </div>
                        <div class="recent-survey-body">
                          <div class="recent-survey-para">
                            <p>
                                {{ survey.title }} </p>
                          </div>
                          <div class="completed_btn">
                            <a class="completed_btn_p" :href="survey._links.display"
                              >Start
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="11"
                                viewBox="0 0 8 9"
                                fill="none"
                              >
                                <g clip-path="url(#clip0_703_2141)">
                                  <path
                                    d="M7.57857 3.77431L1.29286 0.116307C0.782143 -0.180764 0 0.107518 0 0.842283V8.15654C0 8.81572 0.726786 9.21299 1.29286 8.88252L7.57857 5.22627C8.13929 4.90107 8.14107 4.09951 7.57857 3.77431Z"
                                    fill="#259B35"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_703_2141">
                                    <rect width="8" height="9" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                </div>
                <div class="line_one">
  
                </div>
              </div>
            </div>

            <!---->
          </div>
        </div>
      </div>
      <!-- Adding the frame-container and the placeholder -->
      <div class="frame-container">
        <div class="placeholder"></div>
      </div>
    </form>
  </div>
</template>

<script>
import ellipse from "../../img/survey_hero.png";
import LeftNavigationBar from "./LeftNavigationBar";
import TopNavigationBar from "./TopNavigationBar";
import BroadcastFeed from "./BroadcastFeed";
import CareerProfileFrame from "./CareerProfileFrame";
import axios from "axios";
export default {
  name: "HomeFeedStudentDefault",
  components: {
    LeftNavigationBar,
    TopNavigationBar,
    BroadcastFeed,
    CareerProfileFrame,
    ellipse
  },
  props: [
    "jj",
    "leftNavigationBarProps",
    "topNavigationBarProps",
    "broadcastFeedProps",
    "careerProfileFrameProps",
  ],

  data() {
    return {
      ellipse,
      surveys: [],
      tabs: [
        { name: "All Forms", active: true },
        { name: "Skill Forms", active: false },
        { name: "Personality Forms", active: false },
      ],
    };
  },
  mounted() {
    this.fetchSurveys();
  },
  methods: {
    activateTab(index) {
      this.tabs.forEach((tab, tabIndex) => {
        tab.active = index === tabIndex;
      });
    },
  async fetchSurveys() {
      console.log("fetch survyes");
                const serverUrl =
                      process.env.VUE_APP_SERVER_URL || "http://localhost:8080"; // Fallback to localhost in case the env var isn't set
                  const response = await axios.get(`${serverUrl}/v1/surveys`);
                  this.surveys = await response.data.data.items;
                  console.log(this.surveys);
  },
  },
};
</script>

<style scoped>
.home-feed-student-default {
  align-items: flex-start;
  background-color: #f3f7f8;
  border: 1px none;
  display: flex;
  gap: 25px;
  min-height: 1400px;
  overflow: hidden;
  padding: 0 18px;
  position: relative;
  max-width: 1512px;
  width: 100%;
}

@media all and (max-width: 900px) {
  .home-feed-student-default {
    align-items: flex-start;
    background-color: #f3f7f8;
    border: 1px none;
    display: flex;
    gap: 25px;
    height: 982px;
    overflow: hidden;
    padding: 0 18px;
    position: relative;
    max-width: 100vw !important;
  }
}

.flex-col {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 30px;
  min-height: 1579px;
  position: relative;
  width: 100%;
}

.frame-container {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 30px;
  min-height: 927px;
  position: relative;
  right: 0%;
  max-width: 291px;
}

@media all and (max-width: 1400px) {
  .frame-container {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 30px;
    min-height: 927px;
    position: absolute;
    right: 0%;
    max-width: 291px;
  }
}

@media all and (max-width: 600px) {
  .frame-container {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 120px;
    min-height: 927px;
    position: absolute;
    right: 0%;
    max-width: 291px;
  }
}
.frame-2354 {
  align-items: center;
  display: flex;
  margin-right: 12px;
  min-width: 109px;
}

.bell-1 {
  height: 20px;
  width: 20px;
}

.cog-1 {
  height: 20px;
  margin-left: 10px;
  width: 20px;
}

.overlap-group {
  align-items: flex-start;
  background-color: var(--white);
  border: 1px solid;
  border-color: var(--celeste);
  border-radius: 23px;
  display: flex;
  height: 46px;
  margin-left: 13px;
  min-width: 46px;
  padding: 10px 11px;
}

.jj {
  color: var(--x475f4a);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-m);
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  min-height: 24px;
  white-space: nowrap;
}

.broadcast-feed {
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  height: 1479px;
  width: 100%;
}

.overlap-HomeFeedStudentDefaultgroup1 {
  border-radius: 30px;
  height: 951px;
  position: relative;
  width: 100%;
}

@media all and (max-width: 600px) {
  .overlap-HomeFeedStudentDefaultgroup1 {
    border-radius: 30px;
    min-height: 951px;
    position: relative;
    width: 865px;
    margin-top: 80px;
  }
}

.frame-2342 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 30px;
  box-shadow: 0px 0px 15px #0000000d;
  display: inline-flex;
  gap: 10px;
  width: 100%;
  left: 0;
  overflow: hidden;
  overflow-y: scroll;
  padding: 40px 28px;
  position: absolute;
  top: 0;
}

.posts-feeed::-webkit-scrollbar {
  display: none;
}

.posts-feeed {
  align-items: flex-start;
  display: flex;
  gap: 30px;
  height: 350px;
  flex-direction: row;
  flex-wrap: nowrap;

  overflow-x: scroll;
  position: relative;
  width: 100%;
}

.posts-feeed_two {
  align-items: flex-start;
  display: flex;
  gap: 30px;
  margin-top: 40px;

  flex-wrap: wrap;

  overflow-x: scroll;
  position: relative;
  width: 100%;
}

.feed_card {
  border-radius: 20px;
  border: 0.5px solid #ccc;
  background: #fff;
  padding: 30px 20px;
  width: 100%;
}

.headings_card {
  display: flex;
  align-items: center;
  gap: 8px;
}

.heading_image img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.heading_texts h1 {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.heading_texts p {
  color: #ccc;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  margin-top: 2px;
  line-height: normal;
}

.paragraph_card {
  margin-top: 20px;
  margin-bottom: 30px;
}
.paragraph_card p {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.card_buttons {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.card_buttons .link {
  padding: 10px 20px !important;
  border-radius: 100px;
  background: #259b35 !important;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  display: block;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-decoration: none;
  margin-left: auto;
}

.card_social {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 20px;
  justify-content: flex-end;
}

@media all and (max-width: 800px) {
  .tags {
    z-index: 9999;
    height: 100px;
    display: flex;
    flex-wrap: wrap;
    position: relative;
  }
}

/* Base Styles */
.p-4 {
  padding: 20px 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
}

.recent-survey {
  padding: 0.5rem 0.75rem;

  display: inline-flex;
}

.recent-survey-main {
  display: flex;
  flex-direction: column;
}

.recent-survey-heading {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
}

.recent-survey-heading img {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  
}
.recent-survey-heading h1 {
      margin: 0;
      color: #475f4a;
      font-family: Inter;
      font-size: 15px;
      font-style: normal;
      text-transform: capitalize;
      font-weight: 500;
      line-height: normal;
}

.text-xs {
  font-size: 0.75rem;
}

.recent-survey-body {
  margin-top: 0.5rem;
}

.recent-survey-para p {
  color: #000;
  font-family: Inter;
  margin-top: 15px;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.recent-survey-buttons {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
}

.ml-auto {
  margin-left: auto;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.w-full {
  width: 100%;
}

.h-5 {
  height: 1.25rem;
}

.overflow-hidden {
  overflow: hidden;
}

.bg-red-500 {
}

.bg-red-900 {
}

.parent_survey {
  min-width: 300px;
  height: 270px;
  flex-shrink: 1;
  display: inline-flex;
}

.main_percentage {
  border-radius: 20px !important;
  overflow: hidden;
  width: 100%;
  height: 16px;
  background-color: #f3f7f8;
}

.main_percentage_one {
  border-radius: 20px !important;
  background-color: #259b35;
  height: 100%;
  width: 65%;
}
.time {
  color: #a5ac9d;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.flex_col_btn {
  flex-direction: column;
}
.percentage_num {
  color: #a5ac9d;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.top_heading {
  color: #475f4a;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.line_one {
  display: flex;
  gap: 30px;
}

.top_heading_btn_top {
  border-radius: 100px;
  border: 1px solid #259b35;
  background: #fff;
  color: #259b35;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.top_heading_btn_top_survey {
  color: #475f4a;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.tab_main {
  width: 100%;

  border-radius: 20px;
}
.tabs {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  height: 34px;

  border-radius: 100px;
  background: #f3f7f8;
  display: flex;
  gap: 2px;
  align-items: center;
  justify-content: space-between;
}
.one {
  text-align: center;
  width: 100%;
  border-radius: 100px;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  color: #259b35;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.inactive {
  text-align: center;
  width: 100%;
  border-radius: 100px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  color: #a5ac9d;
  font-family: Inter;
  cursor: pointer;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.completed_btn {
  display: flex;
  align-items: center;
  widows: 100%;
}

.completed_btn_a {
  border-radius: 100px;
  background: #259b35 !important;

  text-decoration: none;
  height: 40px;
  margin-top: 20px;
  flex-shrink: 0;
  color: #fff;
  font-family: Inter;
  display: flex;
  width: 50%;
  gap: 6px;
  align-items: center;
  justify-content: center;

  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.completed_btn_d {
  border-radius: 100px;

  text-decoration: none;
  height: 32px;
  margin-top: 20px;
  flex-shrink: 0;
  color: #ccc;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  font-family: Inter;
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: center;

  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.completed_btn_p {
  border-radius: 100px;

  text-decoration: none;
  height: 32px;
  margin-top: 20px;
  flex-shrink: 0;
   
  font-family: Inter;
  gap: 10px;
  font-family: Inter;
  display: flex;
  width: 100px;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  border: 1px solid #259b35;
  background: #fff;
  color: #259B35;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 300;
line-height: normal;
}

.completed_btn_p:hover {
  border-radius: 100px;

  text-decoration: none;
  height: 32px;
  margin-top: 20px;
  flex-shrink: 0;
  color: white;
  font-family: Inter;
  gap: 10px;
  font-family: Inter;
  display: flex;
  width: 100px;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  border: 1px solid #fff;
  background: #259b35;
}
/* Add styles to make the placeholder have similar dimensions and spacing */
.placeholder {
 width: 100%; /* Adjust width as needed */
 height: 927px; /* Use the same height as in your .frame-container styling */
 ackground: transparent; /* No visible background, but you can add one for testing and remove later */
            /* Any other styling you may need to make it look consistent with the HomeFeed */
 }
</style>
