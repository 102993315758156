<template>
  <div class="all-tab">
    <div class="all inter-medium-white-20px">{{ all }}</div>
  </div>
</template>

<script>
export default {
  name: "HomeFeedStudentDefaultFrame2337",
  props: ["all"],
};
</script>

<style>
.all-tab {
  align-items: flex-start;
  background-color: var(--style);
  border-radius: 100px;
  display: inline-flex;
  gap: 10px;
  left: 30px;
  padding: 3px 28px;
  position: absolute;
  top: 30px;
}

 

.all {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
</style>
