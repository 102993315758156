<template>
  <div class="accordion">
    <div class="info_one" v-for="(item, index) in data" :key="index">
      <div class="main" @click="setCurrent(index)">
        <h1 class="my_title">{{ item.title }}</h1>
        <svg
        v-if="current !== index"
          xmlns="http://www.w3.org/2000/svg"
          width="47"
          height="18"
          viewBox="0 0 47 18"
          fill="none"
        >
          <g clip-path="url(#clip0_781_2405)">
            <path
              d="M22.7196 15.6469L12.0712 4.60315C11.6397 4.16252 11.6397 3.45002 12.0712 3.0094L12.7229 2.34377C13.1544 1.90315 13.8521 1.90315 14.2835 2.34377L23.4999 11.9344L32.7163 2.35315C33.1478 1.91252 33.8454 1.91252 34.2769 2.35315L34.9286 3.01877C35.3601 3.4594 35.3601 4.1719 34.9286 4.61252L24.2802 15.6563C23.8487 16.0875 23.1511 16.0875 22.7196 15.6469Z"
              fill="#259B35"
            />
          </g>
          <defs>
            <clipPath id="clip0_781_2405">
              <rect
                width="18"
                height="47"
                fill="white"
                transform="matrix(0 1 -1 0 47 0)"
              />
            </clipPath>
          </defs>
        </svg>

        <svg
        v-else-if="current === index"
          xmlns="http://www.w3.org/2000/svg"
          width="47"
          height="18"
          viewBox="0 0 47 18"
          fill="none"
          class="rotate"
        >
          <g clip-path="url(#clip0_781_2405)">
            <path
              d="M22.7196 15.6469L12.0712 4.60315C11.6397 4.16252 11.6397 3.45002 12.0712 3.0094L12.7229 2.34377C13.1544 1.90315 13.8521 1.90315 14.2835 2.34377L23.4999 11.9344L32.7163 2.35315C33.1478 1.91252 33.8454 1.91252 34.2769 2.35315L34.9286 3.01877C35.3601 3.4594 35.3601 4.1719 34.9286 4.61252L24.2802 15.6563C23.8487 16.0875 23.1511 16.0875 22.7196 15.6469Z"
              fill="#259B35"
            />
          </g>
          <defs>
            <clipPath id="clip0_781_2405">
              <rect
                width="18"
                height="47"
                fill="white"
                transform="matrix(0 1 -1 0 47 0)"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div :class="[current === index ? 'show' : 'hidden', 'decription']">
        <p>
          {{ item.desc }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ScholarPathAccordion",

  data() {
    return {
      current: null,
      data: [
        {
          title: "Styles",
          desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium ducimus adipisci ullam, eos aliquam animi fuga voluptas consequuntur, rem nisi quidem impedit in. Eum quos quisquam similique maiores vero perspiciatis?",
        },
        {
          title: "Personal Info",
          desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium ducimus adipisci ullam, eos aliquam animi fuga voluptas consequuntur, rem nisi quidem impedit in. Eum quos quisquam similique maiores vero perspiciatis?",
        },
        {
          title: "Academics",
          desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium ducimus adipisci ullam, eos aliquam animi fuga voluptas consequuntur, rem nisi quidem impedit in. Eum quos quisquam similique maiores vero perspiciatis?",
        },
        {
          title: "Athletics",
          desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium ducimus adipisci ullam, eos aliquam animi fuga voluptas consequuntur, rem nisi quidem impedit in. Eum quos quisquam similique maiores vero perspiciatis?",
        },
        {
          title: "Clubs & Activities",
          desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium ducimus adipisci ullam, eos aliquam animi fuga voluptas consequuntur, rem nisi quidem impedit in. Eum quos quisquam similique maiores vero perspiciatis?",
        },
        {
          title: "Experience",
          desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium ducimus adipisci ullam, eos aliquam animi fuga voluptas consequuntur, rem nisi quidem impedit in. Eum quos quisquam similique maiores vero perspiciatis?",
        },
        {
          title: "Accomplishments",
          desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium ducimus adipisci ullam, eos aliquam animi fuga voluptas consequuntur, rem nisi quidem impedit in. Eum quos quisquam similique maiores vero perspiciatis?",
        },
      ],
    };
  },

  mounted() {},

  methods: {
    setCurrent(index) {
      if (index === this.current) {
        this.current = null;
      } else {
        this.current = index;
      }
    },
  },
};
</script>

<style scoped>
.main {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
  height: 50px; 
  cursor: pointer;
  padding: 0px 10px;
  justify-content: space-between;
}
.show {
  display: flex;
}

.hidden {
  display: none;
}

.info_one
{
  width: 100%;
  display: flex;
  min-height: 50px; 
  align-items: flex-start;
  flex-direction: column;
  border-bottom: 1px solid rgba(37, 155, 53, 0.13);
 
 
}
.accordion
{
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.main .my_title
{
  color: #000;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.decription{
  color: #000;
font-family: Inter;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.rotate{
  transform: rotate(180deg);
}
</style>
